import clsx from 'clsx';

import styles from './index.module.scss';

interface InputProps {
  label: string;
  value: string;
  placeholder?: string;
  readOnly?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;

  // readOnly가 true일 때 사용될 수 있는 속성들
  onClick?: () => void;

  // readOnly가 false일 때 사용될 수 있는 속성들
  subText?: string;
  hasFeedback?: boolean;
  feedback?: {
    state: 'default' | 'error' | 'success';
    text: string;
  } | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const InputBox = ({
  label,
  value,
  placeholder,
  readOnly = false,
  subText,
  leftIcon,
  rightIcon,
  hasFeedback = false,
  feedback,
  onClick = undefined,
  onChange = undefined,
  onBlur = undefined,
}: InputProps) => {
  if (hasFeedback) {
    return (
      <div
        className={clsx(styles.root, readOnly && styles.readOnly)}
        onClick={onClick}
      >
        <label className={styles.label} htmlFor={label}>
          {label}
        </label>
        <div
          className={clsx(
            styles.inputBoxWithFeedback,
            readOnly && styles.readOnly,
            !value && styles.noValue,
          )}
        >
          <div className={clsx(styles.textBox, !value && styles.noValue)}>
            {subText && <div className={styles.subText}>{subText}</div>}
            <input
              className={styles.input}
              id={label}
              type="text"
              placeholder={placeholder}
              value={value}
              readOnly={readOnly}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
          {feedback && (
            <div
              className={clsx(
                styles.feedback,
                feedback.state && styles[feedback.state],
              )}
            >
              {feedback.text}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(styles.root, readOnly && styles.readOnly)}
      onClick={onClick}
    >
      <label className={styles.label} htmlFor={label}>
        {label}
      </label>
      <div
        className={clsx(
          styles.inputBox,
          readOnly && styles.readOnly,
          !value && styles.noValue,
        )}
      >
        {leftIcon}
        {subText && (
          <div className={clsx(styles.subText, !value && styles.noValue)}>
            {subText}
          </div>
        )}
        <input
          className={styles.input}
          id={label}
          type="text"
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          onChange={onChange}
          onBlur={onBlur}
        />
        {rightIcon}
      </div>
    </div>
  );
};

export default InputBox;
