import clsx from 'clsx';

import styles from './index.module.scss';

type TagSize = 'small' | 'large';

interface TagProps {
  text: string;
  size: TagSize;
  theme:
    | 'neutral1'
    | 'primary1'
    | 'green1'
    | 'yellow1'
    | 'neutral2'
    | 'primary2'
    | 'green2'
    | 'yellow2'
    | 'primary3'
    | 'custom';
  preIcon?: React.ReactNode;
  postIcon?: React.ReactNode;
  // 테마 씹고 커스텀 컬러
  color?: string;
  backgroundColor?: string;
}

const Tag = ({
  text,
  size,
  theme,
  preIcon,
  postIcon,
  color,
  backgroundColor,
}: TagProps) => {
  return (
    <div
      className={clsx(styles.root, styles[size], styles[theme])}
      style={{ color, backgroundColor }}
    >
      {preIcon}
      <div>{text}</div>
      {postIcon}
    </div>
  );
};

export default Tag;
