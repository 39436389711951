import Button from '@/components/Button';
import { useSafeRouter } from '@/hooks';

import BackwardIcon from '@/images/icons/ic_backward_n50_28px.svg';

import styles from '@/components/Mobile/components/GoBackButton.module.scss';

interface GoBackButtonProps {
  onClick?: () => void;
}

const GoBackButton = ({ onClick }: GoBackButtonProps) => {
  const { router } = useSafeRouter();

  const handleGoBackButtonClick = () => {
    if (!onClick) {
      router.back();
      return;
    }

    onClick();
  };

  return (
    <div className={styles.root}>
      <Button
        size="mediumSquare"
        theme="none"
        onClick={handleGoBackButtonClick}
      >
        <BackwardIcon />
      </Button>
    </div>
  );
};

export default GoBackButton;
