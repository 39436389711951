import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { createPortal } from 'react-dom';

import styles from '@/components/Modal/Modal.module.scss';

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

type ModalPortalProps = {
  portalId: string;
};

type ModalProps = {
  children?: ReactNode;
  isOpen: boolean;
  close: () => void;
  isCloseOnOutOfFocusPrevented: boolean;
};

type ModalContentsWrapperProps = {
  children?: ReactNode;
  close: () => void;
  isCloseOnOutOfFocusPrevented: boolean;
};

const ModalPortal = ({
  children,
  portalId,
}: PropsWithChildren<ModalPortalProps>) => {
  const [portal, setPortal] = useState<Element | null>(null);

  const getOrCreateElementById = (id: string): [HTMLElement, boolean] => {
    const element = document.getElementById(id);
    if (element) {
      return [element, false];
    }
    const newElement = document.createElement('div');
    newElement.setAttribute('id', id);
    document.body.appendChild(newElement);
    return [newElement, true];
  };

  useIsomorphicLayoutEffect(() => {
    const [portalElement, isCreated] = getOrCreateElementById(portalId);
    setPortal(portalElement);

    return () => {
      if (isCreated && portalElement.parentNode) {
        portalElement.parentNode.removeChild(portalElement);
      }
    };
  }, [portalId, setPortal]);

  if (!portal) {
    return null;
  }

  return createPortal(children, portal);
};

const isTargetNode = (e: EventTarget | null): e is Node => {
  return e !== null && 'nodeType' in e;
};

const ModalContentsWrapper = ({
  children,
  close,
  isCloseOnOutOfFocusPrevented,
}: ModalContentsWrapperProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const closeOnEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (isCloseOnOutOfFocusPrevented) {
        return;
      }
      if (event.key === 'Escape') {
        close();
      }
    },
    [close, isCloseOnOutOfFocusPrevented],
  );

  const closeOnClickAway = useCallback(
    (event: MouseEvent) => {
      if (isCloseOnOutOfFocusPrevented) {
        return;
      }
      if (
        ref.current &&
        isTargetNode(event.target) &&
        ref.current === event.target
      ) {
        close();
      }
    },
    [close, isCloseOnOutOfFocusPrevented],
  );

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKey);
    document.body.addEventListener('click', closeOnClickAway);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
      document.body.removeEventListener('click', closeOnClickAway);
    };
  }, [closeOnEscapeKey, closeOnClickAway]);

  return (
    <div data-cy={'outside-modal'} ref={ref} className={styles.container}>
      {children}
    </div>
  );
};

export const Modal = ({
  children,
  isOpen,
  close,
  isCloseOnOutOfFocusPrevented,
}: ModalProps) => {
  return (
    <ModalPortal portalId={'root-portal'}>
      <div className="modal">
        {isOpen && (
          <ModalContentsWrapper
            close={close}
            isCloseOnOutOfFocusPrevented={isCloseOnOutOfFocusPrevented}
          >
            {children}
          </ModalContentsWrapper>
        )}
      </div>
    </ModalPortal>
  );
};
