import { useContext, useState } from 'react';

import Button from '@/components/Button';

import MainLayout from '@/components/Layouts/MainLayout';
import ResponsivePageLayout from '@/components/Layouts/ResponsivePageLayout';
import MobileAddTradingrobotPage, {
  AveragePrincipalBanner,
} from '@/components/Mobile/Tradingrobot/New';
import { ModalKind } from '@/components/Modal/ModalTypes';
import {
  TradingRobotModalsProvider,
  useTradingRobotModal,
} from '@/components/Modals/TradingRobotModals';

import { SnackbarContext } from '@/components/Snackbar';
import Spinner from '@/components/Spinner';
import {
  routes,
  MarketName,
  CryptoExchange,
  MAX_ACTIVE_ROBOTS_COUNT,
} from '@/constants/';
import { amplitudeEventNames, trackEvent } from '@/features/amplitude';
import userApi from '@/features/api/account/user';
import { useSafeRouter } from '@/hooks';
import tradingMutation from '@/hooks/useMutation/trading';
import tradingQuery from '@/hooks/useQuery/trading';
import { useTradingrobotStore } from '@/store/tradingrobot';
import clsx from 'clsx';
import { debounce } from 'es-toolkit';

import styles from './index.module.scss';

export interface MarketOption {
  value: MarketName;
  label: string;
}

export const cryptoExchange: CryptoExchange = 'coinone';
export const MIN_PRINCIPAL = 10000;

interface StartButtonProps {
  username: string;
  investment: number | null;
  currentAsset: number | null;
}

const StartButton = ({
  username,
  investment,
  currentAsset,
}: StartButtonProps) => {
  const { safePush } = useSafeRouter();
  const { setSnackbarContents } = useContext(SnackbarContext);
  const { showModal } = useTradingRobotModal();
  const { data: tradingrobots = [] } = tradingQuery.useAllTrading();
  const {
    edittingRobot,
    actions: { setEdittingRobot },
  } = useTradingrobotStore();
  const [isActivating, setIsActivating] = useState(false);
  const createTradingMutation = tradingMutation.useCreateTradingMutation();
  const startTradingMutation = tradingMutation.useStartTradingMutation();

  const activeRobotsCount = tradingrobots.filter(
    (robot) => robot.state === 'UP',
  ).length;

  const validateConfigwithSnackbar = () => {
    if (!edittingRobot) {
      setSnackbarContents({
        mode: 'error',
        text: '전략을 선택해주세요.',
      });

      return false;
    }

    if (!investment) {
      setSnackbarContents({
        mode: 'error',
        text: '유효한 투자금액을 입력해주세요.',
      });

      return false;
    }

    if (
      !investment ||
      investment > Number(currentAsset) ||
      investment < MIN_PRINCIPAL ||
      investment < edittingRobot.min_principal
    ) {
      setSnackbarContents({
        mode: 'error',
        text: '유효한 투자금액을 입력해주세요.',
      });

      return false;
    }

    return true;
  };

  const handleStartClick = debounce(async () => {
    if (!validateConfigwithSnackbar() || !edittingRobot || !investment) {
      return;
    }

    const { name, strategy_id: strategyId } = edittingRobot;

    trackEvent(amplitudeEventNames.BTN_ROBOT_START, {
      value: name,
      exchange: cryptoExchange,
      amount: investment,
    });

    const termsStatus = await userApi.getTerms();
    if (termsStatus.robo_advisor === false) {
      showModal(ModalKind.ConfirmAgreeTermModal);
      return;
    }

    if (activeRobotsCount >= MAX_ACTIVE_ROBOTS_COUNT) {
      setSnackbarContents({
        mode: 'error',
        text: `트레이딩로봇은 ${MAX_ACTIVE_ROBOTS_COUNT}개씩 작동할 수 있어요. 작동 중인 로봇을 중지하고 새 로봇을 작동해주세요. `,
      });

      return;
    }

    try {
      setIsActivating(true);
      const createResponse = await createTradingMutation.mutateAsync({
        name,
        exchange: cryptoExchange,
        market: edittingRobot.market,
        principal: investment,
        strategy_id: strategyId,
        robotName: name,
      });

      if (createResponse.status === 201) {
        try {
          await startTradingMutation.mutateAsync({
            tradingId: createResponse.data,
            robotName: name,
          });

          setSnackbarContents({
            mode: 'success',
            text: '로봇 작동이 시작되었어요.',
          });
        } catch {
          setSnackbarContents({
            mode: 'error',
            text: '로봇 작동 시작에 실패했어요. 다시 시도해주세요.',
          });
        }

        setTimeout(() => {
          safePush(
            routes.tradingrobotDetailRoute({
              username,
              robotId: createResponse.data,
            }),
          );
        }, 500);
      }
    } catch {
      setSnackbarContents({
        mode: 'error',
        text: '로봇 작동 시작에 실패했어요. 다시 시도해주세요.',
      });
    } finally {
      setEdittingRobot(null);
      setTimeout(() => {
        setIsActivating(false);
      }, 500);
    }
  }, 300);

  if (isActivating) {
    return <Spinner />;
  }

  return (
    <Button
      theme="primary1"
      size="large"
      onClick={handleStartClick}
      isClickable={!isActivating}
    >
      로봇 작동하기
    </Button>
  );
};

const DesktopAddTradingrobotPage = () => {
  const { router } = useSafeRouter();
  const username = router.query.username as string;
  const { showModal } = useTradingRobotModal();
  const { edittingRobot } = useTradingrobotStore();
  const { data: currentAsset, isSuccess } =
    tradingQuery.useMyCurrentAssetToExchange({
      username,
    });

  const [investment, setInvestment] = useState<number | null>(null);

  const handleImportClick = () => {
    trackEvent(amplitudeEventNames.BTN_ROBOT_SELECT_BT);
    showModal(ModalKind.ImportRobotStrategyModal);
  };

  const handleInvestmentBlur = () => {
    if (!investment) {
      return;
    }

    if (!isSuccess || currentAsset === null) {
      return;
    }

    if (investment > currentAsset) {
      setInvestment(Math.floor(currentAsset));
    }
  };

  const handleInvestmentChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const { value } = e.target;
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    setInvestment(sanitizedValue === '' ? null : parseInt(sanitizedValue));
  };

  if (!isSuccess) {
    return null;
  }

  return (
    <div className={styles.root}>
      <MainLayout>
        <section className={styles.top}>
          <div className={styles.breadcrumb}>{'내 로봇 > 로봇 설정하기'}</div>
          <p className={styles.notice}>
            *로봇 작동 중 별도 거래시, 작동 중인 로봇이 중지될 수 있습니다.
          </p>
        </section>
        <section className={styles.box}>
          <h2>전략 선택</h2>
          <div className={styles.settingContents}>
            <h3>작동 전략</h3>
            <div className={styles.selectedStrategy}>
              <input
                type="text"
                placeholder="로봇에 적용할 전략을 선택해주세요."
                value={edittingRobot?.name ?? ''}
                readOnly
              />
              <Button
                theme="primary2"
                size="medium"
                onClick={handleImportClick}
              >
                불러오기
              </Button>
            </div>
          </div>
        </section>
        <section className={styles.box}>
          <h2>거래 설정</h2>
          <div className={clsx(styles.settingContents, styles.tradeSettings)}>
            <div className={styles.tradeSetting}>
              <h3>거래소</h3>
              <input type="text" value={'코인원(Coinone)'} disabled />
              {isSuccess && (
                <p className={styles.warning}>
                  보유 원화:
                  {Math.floor(Number(currentAsset)).toLocaleString()}원
                </p>
              )}
            </div>
            <div className={styles.tradeSetting}>
              <h3>거래 종목</h3>
              <div className={styles.selectedMarket}>
                <input
                  type="text"
                  placeholder="전략에 따라 종목이 선택됩니다."
                  value={edittingRobot?.market?.replaceAll('KRW-', '') || ''}
                  readOnly
                />
              </div>
            </div>
            <div className={styles.tradeSetting}>
              <h3>투자 금액 입력</h3>
              <div className={styles.inputWrapper}>
                <input
                  placeholder={`최소 ${edittingRobot?.min_principal.toLocaleString() || '10,000'}원 이상`}
                  value={investment === null ? '' : investment.toLocaleString()}
                  onChange={handleInvestmentChange}
                  onBlur={handleInvestmentBlur}
                />
                <div className={styles.unit}>원</div>
              </div>
              {Boolean(investment) &&
                Number(investment) > Number(currentAsset) && (
                  <p className={styles.warning}>
                    입력한 금액이 보유한 원화보다 커요.
                  </p>
                )}
              {edittingRobot && (
                <p className={styles.warning}>
                  이 전략의 최소 투자 금액은{' '}
                  {edittingRobot.min_principal.toLocaleString()}원 입니다.
                </p>
              )}
            </div>
            <AveragePrincipalBanner />
          </div>
        </section>
        <section className={styles.bottom}>
          <StartButton
            username={username}
            investment={investment}
            currentAsset={currentAsset}
          />
        </section>
      </MainLayout>
    </div>
  );
};

const AddTradingrobotPage = () => {
  return (
    <TradingRobotModalsProvider>
      <ResponsivePageLayout
        desktop={<DesktopAddTradingrobotPage />}
        mobile={<MobileAddTradingrobotPage />}
      />
    </TradingRobotModalsProvider>
  );
};

export default AddTradingrobotPage;
