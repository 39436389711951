import React, { PropsWithChildren, useRef } from 'react';

import Button from '@/components/Button';
import CloseIcon from '@/images/icons/ic_close_n50_28px.svg';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import styles from './BottomSheet.module.scss';
import colors from '@/styles/constants/_colors.module.scss';

const sheetVariants = {
  open: { y: 0 },
  closed: { y: '100%' },
};

const overlayVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  hasCloseBtn?: boolean;
}

const BottomSheet: React.FC<PropsWithChildren<BottomSheetProps>> = ({
  isOpen,
  onClose,
  children,
  hasCloseBtn = false,
}) => {
  const bottomSheetRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {/* Overlay */}
      <motion.div
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        variants={overlayVariants}
        transition={{ duration: 0.3 }}
        className={styles.overlay}
        style={{
          display: isOpen ? 'block' : 'none',
          backgroundColor: `rgba(${colors.n10}, 50%)`,
        }}
      />

      {/* BottomSheet */}
      <motion.div
        ref={bottomSheetRef}
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        variants={sheetVariants}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        className={clsx(styles.bottomSheet)}
      >
        {hasCloseBtn && (
          <div className={styles.action}>
            <Button onClick={onClose} size="none" theme="none">
              <CloseIcon />
            </Button>
          </div>
        )}
        {children}
      </motion.div>
    </>
  );
};

export default BottomSheet;
