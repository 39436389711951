import { CryptoExchange } from '@/constants/';

import { chartAPI } from '@/features/api/chart/base';
import * as Sentry from '@sentry/nextjs';

import { signOut } from 'next-auth/react';

import {
  TradingHolding,
  TradingLog,
  Tradingrobot,
  TradingrobotDetail,
  TradingrobotRequestForm,
  TradingrobotState,
  TradingHistory,
  ReservedPrincipal,
} from './type';

export interface RegisterTradingApiKeyForm {
  crypto_exchange: string;
  access_token: string;
}

export interface TradingAuthInfo {
  crypto_exchange: CryptoExchange;
  access_token: string;
}

const getAllTrading = async (): Promise<Tradingrobot[]> => {
  const response = await chartAPI.get('/trading');
  return response.data;
};

const createTrading = async (form: TradingrobotRequestForm) => {
  const response = await chartAPI.post('/trading', form);
  return response;
};

const getTrading = async (tradingId: string): Promise<TradingrobotDetail> => {
  const response = await chartAPI.get(`/trading/${tradingId}`);
  return response.data;
};

const deleteTrading = async (tradingId: string) => {
  const response = await chartAPI.delete(`/trading/${tradingId}`);
  return response;
};

/**
 * 로보어드바이저의 시작을 요청함.
 * 요청한 양식의 유효성과 상관없이 항상 로보어드바이저의 아이디를 반환.
 * (성공 여부는 받은 아이디를 /api/v1/chart/trading/ 엔드포인트로 요청해서 통해서 확인 가능)
 */

const startTrading = async (tradingId: string) => {
  const response = await chartAPI.post(`/trading/${tradingId}/state`);
  return response;
};

const stopTrading = async (tradingId: string) => {
  const response = await chartAPI.delete(`/trading/${tradingId}/state`);
  return response;
};

const getTradingState = async (
  tradingId: string,
): Promise<TradingrobotState> => {
  const response = await chartAPI.get(`/trading/${tradingId}/state`);
  return response.data;
};

const getTradinglog = async (tradingId: string): Promise<TradingLog[]> => {
  const response = await chartAPI.get(`/trading/${tradingId}/log`);
  return response.data;
};

const getTradingHistory = async (
  tradingId: string,
): Promise<TradingHistory> => {
  const response = await chartAPI.get(`/trading/${tradingId}/history`);
  return response.data;
};

const getTradingHoldings = async (
  tradingId: string,
): Promise<TradingHolding[]> => {
  const response = await chartAPI.get(`/trading/${tradingId}/holdings`);
  return response.data;
};

const getReservedPrincipal = async (
  exchange: CryptoExchange,
): Promise<ReservedPrincipal> => {
  const response = await chartAPI.get(`/trading/balance/${exchange}`);
  return response.data;
};

/**
 * 거래소(코인원 등) AT가 발급됐는지 여부도 확일할 수 있다.
 * 200:
 *  - api key가 등록되어 있어서 성공적으로 자산을 조회
 *  - 없으면 data: null
 * 404: api key가 만료됨, 재발급 필요
 */
const getCurrentAssetToExchange = async (exchange: CryptoExchange) => {
  let retryCount = 0;
  const maxRetries = 3;

  // MEMO: TB-6756, 401 에러 발생 시 로그아웃 처리
  const attemptRequest = async () => {
    try {
      const response = await chartAPI.get(`/trading/asset/${exchange}`);

      if (response.data === null) {
        return null;
      }

      return Number(response.data);
    } catch (error: any) {
      if (error.response?.status === 401) {
        if (retryCount < maxRetries) {
          retryCount += 1;
          return attemptRequest();
        } else {
          signOut();
          return null;
        }
      }

      Sentry.captureMessage('getCurrentAssetToExchange error', {
        level: 'error',
        extra: { error, request: error.request, response: error.response },
      });

      return null;
    }
  };

  return attemptRequest();
};

const registerExchangeAuth = async (form: RegisterTradingApiKeyForm) => {
  const response = await chartAPI.post(`/trading/auth`, form);
  return response.data;
};

const deleteExchangeAuth = async (exchange: CryptoExchange) => {
  await chartAPI.delete(`/trading/auth/${exchange}`);
};

export default {
  createTrading,
  deleteTrading,
  startTrading,
  stopTrading,
  getAllTrading,
  getTrading,
  getTradingState,
  getTradinglog,
  getTradingHistory,
  getTradingHoldings,
  getReservedPrincipal,
  getCurrentAssetToExchange,
  registerExchangeAuth,
  deleteExchangeAuth,
};
