import ScrollToTopButton from '@/components/Button/ScrollToTopButton';

import Tab from '@/components/Header/Tab';
import MobileHeader from '@/components/Mobile/Header';
import StrategyCard from '@/components/Mobile/components/StrategyCard';

import Spinner from '@/components/Spinner';
import { routes } from '@/constants/';
import { amplitudeEventNames, trackEvent } from '@/features/amplitude';
import { MyStrategyOverview } from '@/features/api/chart/strategy/type';
import { useSafeRouter } from '@/hooks';
import strategyQuery from '@/hooks/useQuery/strategy';
import SummaryIcon from '@/images/icons/ic-summary.svg';

import { useSession } from 'next-auth/react';

import styles from '@/components/Mobile/MyStrategy/MobileMyStrategy.module.scss';

const Strategies = ({ strategies }: { strategies: MyStrategyOverview[] }) => {
  const { router, safePush } = useSafeRouter();
  const { username } = router.query;

  const handleStrategyClick = async (id: string, isMine: boolean) => {
    if (!isMine) {
      safePush(routes.marketplaceStrategyRoute(id));
      return;
    }

    safePush(
      routes.myStrategyDetailRoute({
        username: String(username),
        strategyId: id,
      }),
    );
  };

  return (
    <ol className={styles.strategies}>
      {strategies.map((strategy) => {
        const isMine = strategy.author === username;

        return (
          <li
            key={strategy.id}
            role="button"
            onClick={() => handleStrategyClick(strategy.strategy_id, isMine)}
          >
            <StrategyCard strategy={strategy} />
          </li>
        );
      })}
    </ol>
  );
};

export const MobileStrategyPageTab = () => {
  const { router, safePush } = useSafeRouter();

  const { data: session, status } = useSession();

  if (status !== 'authenticated') {
    return null;
  }

  const currentTab = decodeURI(router.asPath.slice(0, -1)); // username이 한국어일 경우를 위해 decodeURI 사용

  const tabs = [
    {
      route: routes.marketplaceRoute,
      title: '전략 마켓',
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_MARKET);
        safePush(routes.marketplaceRoute);
      },
    },
    {
      route: routes.myStrategyRoute(session.username),
      title: '내 전략',
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_STRTG);
        safePush(routes.myStrategyRoute(session.username));
      },
    },
  ];

  return <Tab activeRoute={currentTab} tabs={tabs} type="tab1" />;
};

const MobileMyStrategy = () => {
  const { router } = useSafeRouter();
  const username = router.query.username as string;
  const { data: strategies = [], isLoading } = strategyQuery.useAllStrategies({
    username,
  });

  const filteredStrategies = strategies.filter(({ ror }) => ror);

  return (
    <div className={styles.root}>
      <div>
        <MobileHeader title="전략" />
        <MobileStrategyPageTab />
      </div>
      {isLoading && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
      {!isLoading && filteredStrategies.length > 0 && (
        <Strategies strategies={filteredStrategies} />
      )}
      {!isLoading && filteredStrategies.length === 0 && (
        <div className={styles.noStrategy}>
          <SummaryIcon />
          <div className={styles.text}>
            <p>아직 내 전략이 없어요.</p>
            <p>
              데스크탑에서 전략을 만들어 백테스팅하거나
              <br />
              전략 마켓에서 전문 퀀트트레이더가 공개한 전략을
              <br />
              팔로우해 보세요! 모든 전략은 무료에요.
            </p>
          </div>
        </div>
      )}
      <ScrollToTopButton />
    </div>
  );
};

export default MobileMyStrategy;
