export const queryKeys = {
  MARKETS: 'markets',
  DATA_INDICATORS: 'dataIndicators',
  STRATEGY_CHART: 'strategyChart',
  BACKTESTING_HISTORY: 'backtestingHistory',
  BACKTESTING_SUMMARY: 'backtestingSummary',
  BACKTESTING_SUMMARY_ANALYSIS: 'backtestingSummaryAnalysis',
  BACKTESTING_TASK: 'backtestingTask',
  PREVIEW_TASK: 'previewTask',
  STRATEGY: 'strategy',
  PUBLIC_STRATEGY: 'publicStrategy',
  ALL_PUBLIC_STRATEGIES: 'allPublicBacktestings',
  ALL_BACKTESTINGS: 'backtestings',
  UPDATE_OR_CREATE_BACKTESTING: 'updateOrCreateBacktesting',
  STRATEGY_PERFORMANCE_REPORT: 'strategyPerformanceReport',
  MARKETPLACE_STRATEGY_LIST: 'marketplaceStrategyList',
  MARKETPLACE_TOP_STRATEGY_LIST: 'marketplaceTopStrategyList',
  COPY_STRATEGY_LIST: 'copyStrategyList',
  COPY_STRATEGY_DETAIL: 'copyStrategyDetail',
  MY_CURRENT_ASSET: 'myCurrentAsset',
  IS_RANKING: 'isRanking',
  ALL_TRADING: 'allTrading',
  TRADING: 'trading',
  TRADING_LOG: 'tradingLog',
  TRADING_HOLDINGS: 'tradingHoldings',
  TRADING_HISTORY: 'tradingHistory',
  RESERVED_PRINCIPAL: 'reservedPrincipal',
  TRANSACTION_HISTORY: 'transactionHistory', // coinone

  // tradingbank2 for vip
  BINANCE_BOTS: 'binanceBots',
  BINANCE_PERFORMANCE: 'binancePerformance',
  BINANCE_BOTS_HISTORY: 'binanceBotsHistory',
};
