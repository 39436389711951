import { queryKeys } from '@/constants/';

import strategyApi from '@/features/api/chart/strategy';
import {
  MyStrategyDetail,
  MyStrategyOverview,
  PublicStrategyDetail,
  PublicStrategyOverview,
  StrategyChart,
  StrategyPerformanceReport,
} from '@/features/api/chart/strategy/type';
import { splitDateTimeOfISOString } from '@/features/utils';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

interface StrategyQuery<T> {
  username?: string;
  options?: UseQueryOptions<T>;
}

interface StrategyDetailQuery<T> extends StrategyQuery<T> {
  strategyId: string;
}

interface StrategyChartQuery<T> {
  strategyId: string;
  marketName: string;
  options?: UseQueryOptions<T>;
}

interface StrategyReportQuery<T> {
  options?: UseQueryOptions<T>;
}

const useStrategyPerformanceReport = ({
  options,
}: StrategyReportQuery<StrategyPerformanceReport> = {}) => {
  return useQuery<StrategyPerformanceReport>(
    [queryKeys.STRATEGY_PERFORMANCE_REPORT],
    () => strategyApi.readStrategyPerformanceReport(),
    { ...options },
  );
};

const straetgiesWillBeRemoved = [
  '이동평균 이용 하락장 방어 전략',
  '거래량 많을 때 매수 추세 따라가기 전략',
  '상승 모멘텀 포착 전략',
  '거래량 낮은 것 역이용하는 전략',
  '상승 나타내는 지표 포착 전략',
  '사람들 많이 살 때 따라 사는 전략',
  '4시간봉 분할 전략',
  '이더리움 이용 알트코인 전략',
  '거래량 기반 변동성 전략',
];

const useAllPublicStrategies = ({
  options,
}: StrategyQuery<PublicStrategyOverview[]> = {}) => {
  return useQuery<PublicStrategyOverview[]>(
    [queryKeys.ALL_PUBLIC_STRATEGIES],
    () => strategyApi.readAllPublicStrategies(),
    {
      ...options,
      select: (data) =>
        data.filter((x) => !straetgiesWillBeRemoved.includes(x.name)),
    },
  );
};

const usePublicStrategy = ({
  strategyId,
  options,
}: StrategyDetailQuery<PublicStrategyDetail>) => {
  return useQuery<PublicStrategyDetail>(
    [queryKeys.PUBLIC_STRATEGY, { strategyId }],
    () => strategyApi.readPublicStrategy(strategyId),
    { ...options },
  );
};

const useAllStrategies = ({
  username,
  options,
}: StrategyQuery<MyStrategyOverview[]>) => {
  return useQuery<MyStrategyOverview[]>(
    [queryKeys.ALL_BACKTESTINGS, { username }],
    async () => await strategyApi.readAllStrategies(),
    { ...options },
  );
};

const useStrategy = ({
  strategyId,
  options,
}: StrategyDetailQuery<MyStrategyDetail>) => {
  return useQuery<MyStrategyDetail>(
    [queryKeys.STRATEGY, { strategyId }],
    () => strategyApi.readStrategy(strategyId),
    { ...options },
  );
};

interface GraphPoint {
  x: string;
  y: number;
}

export interface GraphData {
  id: string;
  data: GraphPoint[];
}

interface RawChartData {
  marketDailyRatioValues: GraphPoint[];
  strategyDailyRatioValues: GraphPoint[];
  marketDailyMddValues: GraphPoint[];
  strategyDailyMddValues: GraphPoint[];
}

interface StrategyChartData {
  rorGraphData: GraphData[];
  mddGraphData: GraphData[];
}

const transformStrategyChartPoints = (
  points: StrategyChart['points'],
): RawChartData => {
  return points.reduce<RawChartData>(
    (acc, [datetime, marketChangeRatio, marketMdd, cumulativeRor, mdd]) => {
      const { date } = splitDateTimeOfISOString(datetime);

      acc.marketDailyRatioValues.push({ x: date, y: marketChangeRatio });
      acc.strategyDailyRatioValues.push({ x: date, y: cumulativeRor });
      acc.marketDailyMddValues.push({ x: date, y: marketMdd });
      acc.strategyDailyMddValues.push({ x: date, y: mdd });

      return acc;
    },
    {
      marketDailyRatioValues: [],
      strategyDailyRatioValues: [],
      marketDailyMddValues: [],
      strategyDailyMddValues: [],
    },
  );
};

/**
 * 파싱 작업까지 포함
 */
const useStrategyChart = ({
  strategyId,
  marketName,
}: StrategyChartQuery<StrategyChart>) => {
  return useQuery<StrategyChartData>(
    [queryKeys.STRATEGY_CHART, { strategyId }],
    async () => {
      const strategyChart = await strategyApi.getStrategyChart(strategyId);

      const {
        marketDailyRatioValues,
        strategyDailyRatioValues,
        marketDailyMddValues,
        strategyDailyMddValues,
      } = transformStrategyChartPoints(strategyChart.points);

      const rorGraphData: GraphData[] = [
        {
          id: `${marketName} 가격 변화율`,
          data: marketDailyRatioValues,
        },
        {
          id: '수익률',
          data: strategyDailyRatioValues,
        },
      ];

      const mddGraphData: GraphData[] = [
        {
          id: `${marketName} MDD`,
          data: marketDailyMddValues,
        },
        {
          id: 'MDD',
          data: strategyDailyMddValues,
        },
      ];

      return {
        rorGraphData,
        mddGraphData,
      };
    },
  );
};

export default {
  useStrategyPerformanceReport,
  useAllPublicStrategies,
  usePublicStrategy,
  useAllStrategies,
  useStrategy,
  useStrategyChart,
};
