import clsx from 'clsx';

import styles from './index.module.scss';

interface TitleProps {
  text: string;
  size: 'large' | 'medium' | 'small';
}

const Title = ({ text, size }: TitleProps) => {
  return <div className={clsx(styles.root, styles[size])}>{text}</div>;
};

export default Title;
