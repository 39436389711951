import styles from './CheckCircle.module.scss';

interface CheckCircleProps {
  onChange: () => void;
  checked: boolean;
}

const CheckCircle = (props: CheckCircleProps) => {
  return <input type="checkbox" {...props} className={styles.checkBox} />;
};

export default CheckCircle;
