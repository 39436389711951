export const localStorageKeys = {
  ONBOARDING_FIRST_VISIT: 'TB/onboarding-first-visit',
  ONBOARDING_TUTORIAL: 'TB/onboarding-tutorial',
  AMPLITUDE_FIRST_SESSION: 'TB/amplitude-first-session',
  INSTALL_APP_DISMISSED1: 'TB/install-app-dismissed1',
  INSTALL_APP_DISMISSED2: 'TB/install-app-dismissed2',
};

export const localStorageValues = {
  STARTED: 'started',
  FINISHED: 'finished',
  DISMISSED: 'dismissed',
};
