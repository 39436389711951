import { useContext } from 'react';

import ConfirmAgreeTermModal from '@/components/Modal/ConfirmAgreeTermModal';
import { ConfirmModal } from '@/components/Modal/ConfirmModal/ConfirmModal';
import ImportStrategyModal from '@/components/Modal/ImportStrategyModal/ImportStrategyModal';
import {
  createModalContext,
  RenderModalsProps,
} from '@/components/Modal/ModalProvider';
import { ModalKind, SimpleModalProps } from '@/components/Modal/ModalTypes';
import { SnackbarContext } from '@/components/Snackbar';
import { queryKeys } from '@/constants/';
import { amplitudeEventNames, trackEvent } from '@/features/amplitude';
import userApi from '@/features/api/account/user';
import {
  MyStrategyOverview,
  PublicStrategyOverview,
} from '@/features/api/chart/strategy/type';
import tradingApi from '@/features/api/chart/trading';
import { Tradingrobot } from '@/features/api/chart/trading/type';
import { useSafeRouter } from '@/hooks';
import tradingMutation from '@/hooks/useMutation/trading';
import { queryClient } from '@/hooks/useQuery/base';
import tradingQuery from '@/hooks/useQuery/trading';
import { MIN_PRINCIPAL } from '@/pages/user/[username]/tradingrobot/new';
import { useTradingrobotStore } from '@/store/tradingrobot';
import * as Sentry from '@sentry/nextjs';

import styles from './TradingRobotModals.module.scss';

type TradingRobotDetailState = Record<string, never>;

interface ConfirmStartTradingBotModalProps extends SimpleModalProps {
  currentAsset: number | null;
}

const ConfirmStartTradingBotModal = ({
  onClose,
  onSubmit,
  currentAsset,
}: ConfirmStartTradingBotModalProps) => {
  const { startingRobot } = useTradingrobotStore();

  if (!startingRobot || !currentAsset) {
    return null;
  }

  const { name, markets, principal } = startingRobot;
  const market = markets[0];

  return (
    <ConfirmModal
      title={'로봇 작동을 시작하시겠습니까?'}
      onClose={onClose}
      buttonText="작동 시작"
      onSubmit={onSubmit}
    >
      <div className={styles.startModal}>
        <div className={styles.contents}>
          <p>아래 설정값으로 로봇이 작동됩니다.</p>
          <dl>
            <div>
              <dt>작동 전략</dt>
              <dd>[{name}]</dd>
            </div>
            <div>
              <dt>거래소</dt>
              <dd>코인원</dd>
            </div>
            <div>
              <dt>거래 종목</dt>
              <dd>{market.name.replace('KRW-', '')}</dd>
            </div>
            <div>
              <dt>투자 금액</dt>
              <dd>
                <div>{parseInt(principal).toLocaleString()}원</div>
                <div className={styles.currentAsset}>
                  {`*투자 가능한 금액: ${parseInt(
                    currentAsset.toString(),
                  ).toLocaleString()}원`}
                </div>
              </dd>
            </div>
          </dl>
        </div>
        <div className={styles.warnings}>
          <p>*기존 보유 종목은 유지됩니다.</p>
          <p>*로봇 작동 중 별도 거래시, 작동 중인 로봇이 중지될 수 있습니다.</p>
        </div>
      </div>
    </ConfirmModal>
  );
};

const ConfirmStopTradingBotModal = ({
  onClose,
  onSubmit,
}: SimpleModalProps) => {
  const { stoppingRobot } = useTradingrobotStore();

  if (!stoppingRobot) {
    return null;
  }

  return (
    <ConfirmModal
      title="로봇 작동을 종료하시겠어요?"
      onClose={onClose}
      buttonText="작동 종료"
      onSubmit={onSubmit}
      theme="secondary1"
    >
      <p>
        작동이 종료되면 보유 종목은 모두 시장가로 처분되고, 미체결 주문은
        취소되지 않아요.
      </p>
    </ConfirmModal>
  );
};

const TradingRobotModals = ({
  hideModal,
}: RenderModalsProps<TradingRobotDetailState>) => {
  const { router } = useSafeRouter();
  const { query } = router;
  const username = query.username as string;
  const {
    startingRobot,
    stoppingRobot,
    actions: { setEdittingRobot, setStartingRobot, setStoppingRobot },
  } = useTradingrobotStore();

  const { setSnackbarContents } = useContext(SnackbarContext);
  const startTradingMutation = tradingMutation.useStartTradingMutation();

  const { data: currentAsset, isSuccess } =
    tradingQuery.useMyCurrentAssetToExchange({
      username,
    });

  const handleStrategySelect = async (
    strategy: MyStrategyOverview | PublicStrategyOverview,
  ) => {
    setEdittingRobot({
      strategy_id: strategy.strategy_id,
      name: strategy.name,
      market: strategy.markets[0],
      min_principal: strategy.min_principal || MIN_PRINCIPAL,
    });
    hideModal();
  };

  const handleStartTradingRobot = async (robot: Tradingrobot | null) => {
    if (!robot) {
      setSnackbarContents({
        mode: 'error',
        text: '로봇 작동 종료에 실패했습니다. 다시 시도해주세요.',
      });
      return;
    }

    trackEvent(amplitudeEventNames.BTN_ROBOT_START, {
      value: robot.name,
      exchange: robot.exchange,
      market: robot.markets[0],
      amount: robot.principal,
    });

    try {
      const startResponse = await startTradingMutation.mutateAsync({
        tradingId: robot.id,
        robotName: robot.name,
      });

      Sentry.captureMessage('startTrading', {
        level: 'info',
        extra: {
          response: startResponse,
        },
      });

      setSnackbarContents({
        mode: 'success',
        text: `[${robot.name}] 로봇의 작동이 시작되었습니다.`,
      });
    } catch {
      setSnackbarContents({
        mode: 'error',
        text: '로봇 작동 시작에 실패했습니다. 다시 시도해주세요.',
      });
    } finally {
      queryClient.invalidateQueries([queryKeys.ALL_TRADING]);
      hideModal();
      setStartingRobot(null);
    }
  };

  const handleStopTradingRobot = async (robot: Tradingrobot | null) => {
    if (!robot) {
      setSnackbarContents({
        mode: 'error',
        text: '로봇 작동 종료에 실패했습니다. 다시 시도해주세요.',
      });
      return;
    }

    trackEvent(amplitudeEventNames.BTN_ROBOT_STOP, {
      value: robot.name,
      exchange: robot.exchange,
      market: robot.markets[0],
      amount: robot.principal,
      pnl: robot.markets[0].real_pnl,
      time: `${robot?.last_started_at}에 시작`,
    });

    try {
      const res = await tradingApi.stopTrading(robot.id);

      if (res.status === 204) {
        setSnackbarContents({
          mode: 'success',
          text: `[${robot.name}] 로봇의 작동이 종료되었습니다.`,
        });
      }
    } catch {
      setSnackbarContents({
        mode: 'error',
        text: `[${robot.name}] 로봇 작동 종료에 실패했습니다. 다시 시도해주세요.`,
      });
    } finally {
      queryClient.invalidateQueries([queryKeys.ALL_TRADING]);
      hideModal();
      setStoppingRobot(null);
    }
  };

  const handleTermAgreeSubmit = async () => {
    trackEvent(amplitudeEventNames.BTN_ROBOT_AGREE);
    const hasAgreed = await userApi.agreeRoboTerms();

    if (hasAgreed) {
      setSnackbarContents({
        mode: 'success',
        text: '약관 동의가 완료되었습니다.',
      });
    }

    hideModal();
  };

  if (!isSuccess) {
    return [];
  }

  return [
    <ConfirmStartTradingBotModal
      key={ModalKind.ConfirmStartTradingBotModal}
      kind={ModalKind.ConfirmStartTradingBotModal}
      onClose={hideModal}
      onSubmit={() => {
        handleStartTradingRobot(startingRobot);
      }}
      currentAsset={currentAsset}
    />,
    <ConfirmStopTradingBotModal
      key={ModalKind.ConfirmStopTradingBotModal}
      kind={ModalKind.ConfirmStopTradingBotModal}
      onClose={hideModal}
      onSubmit={() => {
        handleStopTradingRobot(stoppingRobot);
      }}
    />,
    <ImportStrategyModal
      key={ModalKind.ImportRobotStrategyModal}
      kind={ModalKind.ImportRobotStrategyModal}
      onClose={hideModal}
      onSelect={handleStrategySelect}
      mode="robot"
    />,
    <ConfirmAgreeTermModal
      key={ModalKind.ConfirmAgreeTermModal}
      kind={ModalKind.ConfirmAgreeTermModal}
      onClose={hideModal}
      onSubmit={handleTermAgreeSubmit}
    />,
  ];
};

const { ModalProvider, useModal } = createModalContext({}, TradingRobotModals);

export {
  ModalProvider as TradingRobotModalsProvider,
  useModal as useTradingRobotModal,
};
