import { forwardRef, useContext, useEffect, useState } from 'react';

import Button from '@/components/Button';
import CheckCircle from '@/components/CheckCircle/CheckCircle';

import Menu from '@/components/Content/Menu';
import Tag from '@/components/Content/Tag';
import Title from '@/components/Content/Title';
import Divider from '@/components/Divider';
import RiskIcon from '@/components/Icon/RiskIcon';
import {
  marketFilters,
  minPrincipalFilters,
  sortOrders,
  styleFilters,
} from '@/components/Mobile/Marketplace';
import BottomSheet from '@/components/Mobile/components/BottomSheet';
import StrategyCard from '@/components/Mobile/components/StrategyCard';
import { AgreementTerms } from '@/components/Modal/ConfirmAgreeTermModal';
import { SnackbarContext } from '@/components/Snackbar';
import { routes } from '@/constants/';
import { amplitudeEventNames, trackEvent } from '@/features/amplitude';
import userApi from '@/features/api/account/user';
import strategyApi from '@/features/api/chart/strategy';
import { fireAlert } from '@/features/utils';
import { useSafeRouter } from '@/hooks';
import strategyQuery from '@/hooks/useQuery/strategy';
import SummaryIcon from '@/images/icons/ic-summary.svg';
import GreenCheckIcon from '@/images/icons/ic_check-circle_green60n10_24px.svg';
import CheckIcon from '@/images/icons/ic_check-circle_n40p40_24px.svg';
import RobotIcon from '@/images/icons/ic_robot_40px.svg';
import AlarmConsentIllust from '@/images/illust/il_alarmconsent.svg';
import AppIconIllust from '@/images/illust/il_appicon_80px.svg';
import GrayCoinIllust from '@/images/illust/il_graycoin_80px.svg';
import { MIN_PRINCIPAL } from '@/pages/user/[username]/tradingrobot/new';
import { useTradingrobotStore } from '@/store/tradingrobot';

import { useSession, signOut } from 'next-auth/react';

import styles from './index.module.scss';
// TODO: 많아지면 TooltipSheet로 만들기
export const ScoreTooltipSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
  }
>(({ isOpen, setIsOpen, onClose }, ref) => {
  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.scoreTooltipSheet}>
        <div className={styles.content}>
          <div className={styles.header}>
            전략 점수는
            <br />
            다음과 같은 기준으로 매겨져요.
          </div>
          <div className={styles.body}>
            샤프 지수, 회전율, 승률, 위험도의 점수를 합산한 후,
            <br />
            각 항목별 가중치를 고려하여 최종 점수를 산정하고 있어요.
            <br />
            <br />
            40 이하 : 고위험 노출
            <br />
            41 ~ 50 : 리스크 관리 필요
            <br />
            51 ~ 60 : 안정성 미흡
            <br />
            61 ~ 70 : 보통 수준 수익성
            <br />
            71 ~ 80 : 양호한 수익 잠재력
            <br />
            81 이상 : 안정적 고수익 가능
          </div>
        </div>
        <Button
          onClick={handleCancelClick}
          size="large"
          theme="neutral3"
          isFullWidth
        >
          닫기
        </Button>
      </div>
    </BottomSheet>
  );
});

export const DCATooltipSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
  }
>(({ isOpen, setIsOpen, onClose }, ref) => {
  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.dcaTooltipSheet}>
        <div className={styles.content}>
          <div className={styles.header}>분할 매수를 하면</div>
          <div className={styles.body}>
            분할 매수는 한 번에 전부 사지 않고 여러 번 나눠서 사는 방법이에요.
            나눠서 매수를 하면 시장이 오르내려도 위험을 줄이고, 더 좋은 가격에
            살 기회를 얻을 수 있어요.
          </div>
        </div>
        <Button
          onClick={handleCancelClick}
          size="large"
          theme="neutral3"
          isFullWidth
        >
          닫기
        </Button>
      </div>
    </BottomSheet>
  );
});

export const RiskTooltipSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
  }
>(({ isOpen, setIsOpen, onClose }, ref) => {
  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.riskTooltipSheet}>
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.header}>
              <div className={styles.title}>위험도</div>
              <p className={styles.description}>
                MDD(최대낙폭)로, 백테스팅 기간 동안 자산이 최대로 떨어졌을 때의
                손실률을 나타내요. 스트레스 지수라고도 불려요.
                트레이딩뱅크에서는 위험도를 3단계로 나누어서 표현해요.
              </p>
            </div>
            <div className={styles.body}>
              <div className={styles.row}>
                <Tag
                  text={'높음'}
                  size="small"
                  theme="neutral1"
                  preIcon={<RiskIcon mdd={30} />}
                />
                <div>30% 이상</div>
              </div>
              <div className={styles.row}>
                <Tag
                  text={'보통'}
                  size="small"
                  theme="neutral1"
                  preIcon={<RiskIcon mdd={10} />}
                />
                <div>10% 이상 - 30% 미만</div>
              </div>
              <div className={styles.row}>
                <Tag
                  text={'안전'}
                  size="small"
                  theme="neutral1"
                  preIcon={<RiskIcon mdd={0} />}
                />
                <div>10% 미만</div>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              onClick={handleCancelClick}
              size="large"
              theme="neutral3"
              isFullWidth
            >
              닫기
            </Button>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
});

export const MinPrincipalTooltipSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
  }
>(({ isOpen, setIsOpen, onClose }, ref) => {
  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.minPrincipalTooltipSheet}>
        <div className={styles.content}>
          <div className={styles.header}>최소 투자 금액이 왜 필요한가요?</div>
          <div className={styles.body}>
            <p>
              이 전략은 단계별로 투자해 리스크를 분산하는 분할매수 전략이에요.
              전략이 의도대로 작동하려면 최소 투자 금액을 충족해야 해요.
            </p>
            <div>
              예를 들어, 1:2:3 비율로 3차 분할매수를 진행하면, 최소 투자 금액은
              36,000원이 돼요. (최소 투자 금액은 트레이딩뱅크의 최소 주문 단위와
              분할매수 비율로 결정돼요.)
              <ol className={styles.example}>
                <li>- 1차 매수: 6,000원 ( 최소 주문 단위)</li>
                <li>- 2차 매수: 12,000원</li>
                <li>- 3차 매수: 18,000원</li>
              </ol>
            </div>
          </div>
        </div>
        <Button
          onClick={handleCancelClick}
          size="large"
          theme="neutral3"
          isFullWidth
        >
          닫기
        </Button>
      </div>
    </BottomSheet>
  );
});

export const PaperRobotTooltipSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
  }
>(({ isOpen, setIsOpen, onClose }, ref) => {
  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.paperRobotTooltipSheet}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.main}>
              <div className={styles.header}>트레이딩뱅크 모의투자</div>
              <div className={styles.body}>
                전략이 마켓에 등록된 시점부터 현재까지의 성과를 바탕으로 리스크
                없이 투자 연습할 수 있어요. 전략 페이지에서 ‘모의 투자하기’
                버튼을 눌러 모의투자 로봇을 작동해보세요!
              </div>
            </div>
            <div className={styles.caption}>
              <div className={styles.row}>
                <div className={styles.icon}>
                  <CheckIcon />
                </div>
                <p>
                  해당 서비스는 트레이딩뱅크의 전략, 백테스팅 정보를 활용한
                  모의투자 서비스에요.
                </p>
              </div>
              <div className={styles.row}>
                <div className={styles.icon}>
                  <CheckIcon />
                </div>
                <p>이 서비스에서 얻은 수익은 실제 현금과 교환할 수 없어요.</p>
              </div>
            </div>
          </div>
          <Button
            onClick={handleCancelClick}
            size="large"
            theme="neutral3"
            isFullWidth
          >
            닫기
          </Button>
        </div>
      </div>
    </BottomSheet>
  );
});

export const LogoutSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
  }
>(({ isOpen, setIsOpen, onClose }, ref) => {
  const handleLogoutClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation(); // 버블링 방지
    await signOut();
  };

  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.logoutSheet}>
        <div className={styles.content}>
          <div className={styles.header}>정말 로그아웃 하시겠어요?</div>
          <div className={styles.buttons}>
            <Button
              onClick={handleCancelClick}
              size="large"
              theme="neutral2"
              isFullWidth
            >
              취소
            </Button>
            <Button
              onClick={handleLogoutClick}
              size="large"
              theme="primary1"
              isFullWidth
            >
              로그아웃
            </Button>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
});

export const WithdrawlSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
  }
>(({ isOpen, setIsOpen, onClose }, ref) => {
  const handleWithdrawlClick = async () => {
    try {
      await userApi.deleteUser();
      await signOut({ callbackUrl: routes.signInRoute });
    } catch {
      fireAlert({ text: '회원탈퇴에 실패했습니다. 다시 시도해주세요.' });
    }
  };

  const handleCancelClick = () => {
    setIsOpen(false);
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.withdrawSheet}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>정말 탈퇴하시겠어요?</div>
            <div className={styles.subTitle}>
              탈퇴하시면 회원님의 모든 정보와 활동 기록이 삭제됩니다. 삭제된
              정보는 복구할 수 없으니 신중하게 결정해주세요.
            </div>
            <div className={styles.subTitle}>
              *탈퇴 후 재가입은 고객센터를 통해 문의해주세요
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              onClick={handleCancelClick}
              size="large"
              theme="neutral2"
              isFullWidth
            >
              취소
            </Button>
            <Button
              onClick={handleWithdrawlClick}
              size="large"
              theme="secondary1"
              isFullWidth
            >
              회원탈퇴
            </Button>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
});

export const StrategiesSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
    username: string;
  }
>(({ isOpen, setIsOpen, onClose, username }, ref) => {
  const { safePush } = useSafeRouter();
  const {
    actions: { setEdittingRobot },
  } = useTradingrobotStore();

  const [edittingRobotStrategyId, setEdittingRobotStrategyId] = useState<
    string | undefined
  >(undefined);
  const { data: strategies = [] } = strategyQuery.useAllStrategies({
    username,
  });

  useEffect(() => {
    (async () => {
      if (!edittingRobotStrategyId) {
        return;
      }
      if (
        strategies
          .filter((strategy) => strategy.author === username)
          .find((x) => x.strategy_id === edittingRobotStrategyId)
      ) {
        const detail = await strategyApi.readStrategy(edittingRobotStrategyId);

        setEdittingRobot({
          strategy_id: detail.strategy_id,
          name: detail.name,
          market: detail.markets[0],
          min_principal: detail.strategy.min_principal || MIN_PRINCIPAL,
        });
        setIsOpen(false);
        return;
      }

      const detail = await strategyApi.readPublicStrategy(
        edittingRobotStrategyId,
      );
      setEdittingRobot({
        strategy_id: detail.strategy_id,
        name: detail.name,
        market: detail.markets[0],
        min_principal: detail.strategy.min_principal || MIN_PRINCIPAL,
      });
      setIsOpen(false);
    })();
  }, [
    edittingRobotStrategyId,
    setEdittingRobot,
    setIsOpen,
    strategies,
    username,
  ]);

  const handleStrategyClick = (strategyId: string) => {
    setEdittingRobotStrategyId(strategyId);
  };

  const handleMarketplaceClick = () => {
    safePush(routes.marketplaceRoute);
  };

  const hasStrategyAtLeastOne =
    strategies.filter((strategy) => Boolean(strategy.ror)).length > 0;

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={onClose}
      hasCloseBtn={hasStrategyAtLeastOne ? true : false}
    >
      <div ref={ref} className={styles.strategiesSheet}>
        {hasStrategyAtLeastOne && (
          <div className={styles.header}>
            <Title size="large" text="어떤 전략으로 투자해볼까요?" />
            <div className={styles.description}>
              투자할 전략을 선택해 주세요.
            </div>
          </div>
        )}
        <div className={styles.body}>
          {hasStrategyAtLeastOne && (
            <ul>
              {strategies
                .filter((strategy) => Boolean(strategy.ror))
                .map((strategy) => (
                  <li
                    key={strategy.id}
                    role="button"
                    onClick={() => handleStrategyClick(strategy.strategy_id)}
                  >
                    <StrategyCard strategy={strategy} />
                  </li>
                ))}
            </ul>
          )}
          {!hasStrategyAtLeastOne && (
            <div className={styles.noResult}>
              <SummaryIcon />
              <div className={styles.text}>
                <p>백테스팅이 완료된 전략이 없어요.</p>
                <p>전문 퀀트 트레이더가 올린 전략을 구경해보실래요?</p>
              </div>
              <Button
                theme="primary1"
                size="medium"
                onClick={handleMarketplaceClick}
              >
                전략 구경하러 가기
              </Button>
            </div>
          )}
        </div>
      </div>
    </BottomSheet>
  );
});

export const AgreementSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
  }
>(({ isOpen, setIsOpen, onClose }, ref) => {
  const { setSnackbarContents } = useContext(SnackbarContext);
  const [isAgreed, setIsAgreed] = useState(false);

  const handleCheckClick = () => {
    setIsAgreed(!isAgreed);
  };

  const handleCancelClick = () => {
    setIsOpen(false);
  };

  const handleAgreeClick = async () => {
    trackEvent(amplitudeEventNames.BTN_ROBOT_AGREE);
    const hasAgreed = await userApi.agreeRoboTerms();

    if (hasAgreed) {
      setSnackbarContents({
        mode: 'success',
        text: '약관 동의가 완료되었습니다.',
      });
    }

    setIsOpen(false);
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.agreementSheet}>
        <p className={styles.title}>
          트레이딩뱅크 실전 매매 연결
          <br />
          유의사항 및 이용 동의
        </p>
        <AgreementTerms />
        <div className={styles.agreeRow}>
          <CheckCircle checked={isAgreed} onChange={handleCheckClick} />위
          유의사항을 모두 확인했습니다.
        </div>
        <div className={styles.buttons}>
          <Button
            theme="neutral2"
            size="large"
            onClick={handleCancelClick}
            isFullWidth
          >
            취소
          </Button>
          <Button
            theme="primary1"
            size="large"
            onClick={handleAgreeClick}
            isClickable={isAgreed}
            isFullWidth
          >
            동의하기
          </Button>
        </div>
      </div>
    </BottomSheet>
  );
});

export const PaperTradingSheet = forwardRef<
  HTMLDivElement,
  {
    robotName: string;
    paperTradingId: string;
    strategyId: string;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
  }
>(
  (
    { robotName, paperTradingId, strategyId, isOpen, setIsOpen, onClose },
    ref,
  ) => {
    const { setSnackbarContents } = useContext(SnackbarContext);
    const { safePush } = useSafeRouter();
    const { data: session } = useSession();

    const handleStartClick = async (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      event.stopPropagation(); // 버블링 방지
      if (!session) {
        return;
      }

      trackEvent(amplitudeEventNames.BTN_PAPERROBOT_ADD_START, {
        value: robotName,
      });

      try {
        await strategyApi.followPaperTrading(String(strategyId));
        setSnackbarContents({
          mode: 'success',
          text: '모의투자 로봇 작동이 시작되었어요. 매수가 시작되면 알림을 드릴게요.',
        });
        safePush(
          routes.tradingrobotDetailRoute({
            username: session.username,
            robotId: paperTradingId,
          }),
        );
      } catch {
        setSnackbarContents({
          mode: 'error',
          text: '모의투자 로봇 작동을 실패했어요. 다시 시도해주세요.',
        });
      }
    };

    const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setIsOpen(false);
    };

    return (
      <BottomSheet isOpen={isOpen} onClose={onClose}>
        <div ref={ref} className={styles.paperTradingSheet}>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.title}>
                이 전략으로 모의투자를 해볼까요?
              </div>
              <div className={styles.caption}>
                <div className={styles.row}>
                  <div className={styles.icon}>
                    <CheckIcon />
                  </div>
                  <p>
                    해당 서비스는 트레이딩뱅크의 전략, 백테스팅 정보를 활용한
                    모의투자 서비스에요.
                  </p>
                </div>
                <div className={styles.row}>
                  <div className={styles.icon}>
                    <CheckIcon />
                  </div>
                  <p>이 서비스에서 얻은 수익은 실제 현금과 교환할 수 없어요.</p>
                </div>
                <div className={styles.row}>
                  <div className={styles.icon}>
                    <CheckIcon />
                  </div>
                  <p>
                    모의투자 로봇은 투자 금액이 1,000만 원으로 고정되어 있어요.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.buttons}>
              <Button onClick={handleCancelClick} size="large" theme="neutral2">
                취소
              </Button>
              <Button
                onClick={handleStartClick}
                size="large"
                theme="primary1"
                isFullWidth
              >
                모의투자 시작하기
              </Button>
            </div>
          </div>
        </div>
      </BottomSheet>
    );
  },
);

export const RobotStartNoticeSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    onConfirmClick: () => void;
    onClose: () => void;
  }
>(({ isOpen, onConfirmClick, onClose }, ref) => {
  const handleConfirmClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation(); // 버블링 방지
    onConfirmClick();
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.robotStartNoticeSheet}>
        <div className={styles.content}>
          <div className={styles.header}>
            <GreenCheckIcon />
            투자 로봇이
            <br />
            작동을 시작했어요.
          </div>
          <div className={styles.body}>
            선택한 전략의 조건에 따라 로봇이 매수 타이밍을 찾고 있어요. 거래가
            발생하면 푸시 알림으로 알려드릴게요!
          </div>
          <Button
            onClick={handleConfirmClick}
            size="large"
            theme="primary1"
            isFullWidth
          >
            확인
          </Button>
        </div>
      </div>
    </BottomSheet>
  );
});

export const RobotStartTutorialSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    onClick: () => void;
    onClose: () => void;
  }
>(({ isOpen, onClick, onClose }, ref) => {
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // 버블링 방지
    onClick();
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.robotStartTutorialSheet}>
        <div className={styles.content}>
          <div className={styles.header}>
            <RobotIcon />
            <p className={styles.p1}>안전한 투자에 한발짝 더 가까워졌어요</p>
            <p className={styles.p2}>
              이제 거래소 연결하고 <br />
              나만의 로봇을 작동해 보세요!
            </p>
          </div>
          <Button
            onClick={handleClick}
            size="large"
            theme="primary1"
            isFullWidth
          >
            거래소 연결하기
          </Button>
        </div>
      </div>
    </BottomSheet>
  );
});

export const PushNotificationNoticeSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    onClick: () => void;
    onClose: () => void;
  }
>(({ isOpen, onClick, onClose }, ref) => {
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // 버블링 방지
    onClick();
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.pushNotificationNoticeSheet}>
        <div className={styles.contentWrap}>
          <div className={styles.content1}>
            <p className={styles.p1}>
              로봇이 거래하면 <br />
              빠르게 알려드릴게요!
            </p>
            <p className={styles.p2}>
              알림을 허용하시면 모의투자와 실전투자의 로봇의 <br />
              실시간 거래를 푸시 알림으로 받을 수 있어요.
            </p>
          </div>
          <AlarmConsentIllust />
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={handleClick}
            size="large"
            theme="neutral3"
            isFullWidth
          >
            알림 설정으로
          </Button>
          <Button onClick={onClose} size="large" theme="neutral2" isFullWidth>
            나중에 하기
          </Button>
        </div>
      </div>
    </BottomSheet>
  );
});

export const InstallAppSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    onClick: () => void;
    onClose: () => void;
  }
>(({ isOpen, onClick, onClose }, ref) => {
  const handleAppClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // 버블링 방지
    onClick();
  };

  const handleDismissClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation(); // 버블링 방지
    onClose();
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.installAppSheet}>
        <div className={styles.content}>
          <AppIconIllust />
          <div className={styles.text}>
            <div className={styles.p1}>
              실시간 알림으로
              <br />더 편리하게 관리하세요!
            </div>
            <div className={styles.p2}>
              앱에서는 로봇의 거래를 실시간으로 알려드려요.
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={handleAppClick}
            size="large"
            theme="primary1"
            isFullWidth
          >
            편리한 앱으로 보기
          </Button>
          <button onClick={handleDismissClick} className={styles.dismiss}>
            괜찮아요, 모바일웹으로 볼게요.
          </button>
        </div>
      </div>
    </BottomSheet>
  );
});

export const InstallAppSheet2 = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    onClick: () => void;
    onClose: () => void;
  }
>(({ isOpen, onClick, onClose }, ref) => {
  const handleAppClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // 버블링 방지
    onClick();
  };

  const handleDismissClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation(); // 버블링 방지
    onClose();
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.installAppSheet}>
        <div className={styles.content}>
          <AppIconIllust />
          <div className={styles.text}>
            <div className={styles.p1}>
              이 전략,
              <br />
              실전에서도 놓치지 마세요!
            </div>
            <div className={styles.p2}>
              앱에서는 로봇의 거래를 실시간으로 알려드려요.
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={handleAppClick}
            size="large"
            theme="primary1"
            isFullWidth
          >
            편리한 앱으로 보기
          </Button>
          <button onClick={handleDismissClick} className={styles.dismiss}>
            괜찮아요, 모바일웹으로 볼게요.
          </button>
        </div>
      </div>
    </BottomSheet>
  );
});

export const SortOrderSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    onClose: () => void;
    selectedSortOrder: string;
    setSortOrder: (sortOrder: (typeof sortOrders)[number]) => void;
  }
>(({ isOpen, onClose, selectedSortOrder, setSortOrder }, ref) => {
  const eventNamesBySortOrder: Record<(typeof sortOrders)[number], string> = {
    최신순: amplitudeEventNames.BTN_SORT_LATEST,
    '점수 높은 순': amplitudeEventNames.BTN_SORT_SCORE,
    '최근 3개월 수익률 높은 순': amplitudeEventNames.BTN_SORT_RETURN,
    '누적 거래대금 높은 순': amplitudeEventNames.BTN_SORT_TRANSACTIONAMOUNT,
    '누적 손익 높은 순': amplitudeEventNames.BTN_SORT_PNL,
  };

  const handleApplyClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation(); // 버블링 방지
    trackEvent(amplitudeEventNames.BTN_SORT_APPLY);
    onClose();
  };

  const handleSortOrderClick = (sortOrder: (typeof sortOrders)[number]) => {
    trackEvent(eventNamesBySortOrder[sortOrder]);
    setSortOrder(sortOrder);
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.sortOrderSheet}>
        <Menu>
          {sortOrders.map((sortOrder) => (
            <Menu.Item
              key={sortOrder}
              value={sortOrder}
              theme={'neutral1'}
              isSelected={selectedSortOrder === sortOrder}
              onClick={() => handleSortOrderClick(sortOrder)}
            />
          ))}
        </Menu>
        <Button
          onClick={handleApplyClick}
          size="large"
          theme="neutral3"
          isFullWidth
        >
          적용하기
        </Button>
      </div>
    </BottomSheet>
  );
});

export const StrategyFilterSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    onClose: () => void;
    selectedStyleFilters: Array<(typeof styleFilters)[number]>;
    selectedMarketFilters: Array<(typeof marketFilters)[number]>;
    selectedMinPrincipalFilters: Array<(typeof minPrincipalFilters)[number]>;
    setSelectedStyleFilters: (
      filters: Array<(typeof styleFilters)[number]>,
    ) => void;
    setSelectedMarketFilters: (
      filters: Array<(typeof marketFilters)[number]>,
    ) => void;
    setSelectedMinPrincipalFilters: (
      filters: Array<(typeof minPrincipalFilters)[number]>,
    ) => void;
  }
>(
  (
    {
      isOpen,
      onClose,
      selectedStyleFilters,
      selectedMarketFilters,
      selectedMinPrincipalFilters,
      setSelectedStyleFilters,
      setSelectedMarketFilters,
      setSelectedMinPrincipalFilters,
    },
    ref,
  ) => {
    const handleApplyClick = async (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      event.stopPropagation(); // 버블링 방지
      trackEvent(amplitudeEventNames.BTN_FILTER_APPLY);
      onClose();
    };

    // 하나만 선택 가능
    const handleStyleFilterToggle = (filter: (typeof styleFilters)[number]) => {
      if (selectedStyleFilters.includes(filter)) {
        setSelectedStyleFilters([]);
        return;
      }

      trackEvent(amplitudeEventNames.BTN_FILTER_STYLE, { value: filter });
      setSelectedStyleFilters([filter]);
    };

    // 여러 개 선택 가능
    const handleMarketFilterToggle = (
      filter: (typeof marketFilters)[number],
    ) => {
      if (selectedMarketFilters.includes(filter)) {
        setSelectedMarketFilters(
          selectedMarketFilters.filter((f) => f !== filter),
        );
        return;
      }

      trackEvent(amplitudeEventNames.BTN_FILTER_MARKET, { value: filter });
      setSelectedMarketFilters([...selectedMarketFilters, filter]);
    };

    // 하나만 선택 가능
    const handleMinPrincipalFilterToggle = (
      filter: (typeof minPrincipalFilters)[number],
    ) => {
      if (selectedMinPrincipalFilters.includes(filter)) {
        setSelectedMinPrincipalFilters([]);
        return;
      }

      trackEvent(amplitudeEventNames.BTN_FILTER_MINIMUM, { value: filter });
      setSelectedMinPrincipalFilters([filter]);
    };

    const isAtLeastOneSelected =
      selectedStyleFilters.length > 0 ||
      selectedMarketFilters.length > 0 ||
      selectedMinPrincipalFilters.length > 0;

    return (
      <BottomSheet isOpen={isOpen} onClose={onClose}>
        <div ref={ref} className={styles.strategyFilterSheet}>
          <div className={styles.wrap}>
            <div className={styles.section}>
              <div className={styles.sectionTitle}>투자 스타일</div>
              <ul className={styles.filterButtons}>
                {styleFilters.map((filter) => (
                  <li key={filter}>
                    <Button
                      size="small"
                      theme={
                        selectedStyleFilters.includes(filter)
                          ? 'primary3'
                          : 'neutral2'
                      }
                      onClick={() => handleStyleFilterToggle(filter)}
                    >
                      {filter}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
            <Divider type="line" color="n30" />
            <div className={styles.section}>
              <div className={styles.sectionTitle}>
                투자 종목
                <div className={styles.subtext}>여러 개 선택 가능</div>
              </div>
              <ul className={styles.filterButtons}>
                {marketFilters.map((filter) => (
                  <li key={filter}>
                    <Button
                      size="small"
                      theme={
                        selectedMarketFilters.includes(filter)
                          ? 'primary3'
                          : 'neutral2'
                      }
                      onClick={() => handleMarketFilterToggle(filter)}
                    >
                      {filter}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
            <Divider type="line" color="n30" />
            <div className={styles.section}>
              <div className={styles.sectionTitle}>최소 투자 금액</div>
              <ul className={styles.filterButtons}>
                {minPrincipalFilters.map((filter) => (
                  <li key={filter}>
                    <Button
                      size="small"
                      theme={
                        selectedMinPrincipalFilters.includes(filter)
                          ? 'primary3'
                          : 'neutral2'
                      }
                      onClick={() => handleMinPrincipalFilterToggle(filter)}
                    >
                      {filter}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Button
            onClick={handleApplyClick}
            size="large"
            theme={isAtLeastOneSelected ? 'neutral3' : 'neutral2'}
            isFullWidth
          >
            적용하기
          </Button>
        </div>
      </BottomSheet>
    );
  },
);

export const BalanceNoticeSheet = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    onClose: () => void;
  }
>(({ isOpen, onClose }, ref) => {
  const handleConfirmClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    window.open('https://apps.coinone.co.kr/4ios5f', '_blank');
    // window.open('coinoneapp://dnw/detail?symbol=krw&filter=all&id=0', '_blank');
  };

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={styles.balanceNoticeSheet}>
        <div className={styles.content}>
          <GrayCoinIllust />
          <div className={styles.text}>
            <div className={styles.p1}>코인원 계좌에 잔액이 부족해요.</div>
            <div className={styles.p2}>
              거래를 위해 계좌 잔액을 확인해 주세요.
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button onClick={onClose} size="large" theme="neutral2" isFullWidth>
            닫기
          </Button>
          <Button
            onClick={handleConfirmClick}
            size="large"
            theme="primary1"
            isFullWidth
          >
            확인하러 가기
          </Button>
        </div>
      </div>
    </BottomSheet>
  );
});
