import { useEffect, useState } from 'react';

/**
 * 클릭 이벤트를 비활성화하는 훅
 * 사실 내용만 보면 상태를 딜레이를 줘 변경할 뿐이라 다른 용도로도 사용할 수 있지만
 * 일단은 바텀시트 오픈 시 클릭 이벤트를 비활성화하는 용도로 만들었기 때문에 아래와 같이 네이밍함.
 * @param willBeDisabled 클릭 이벤트를 비활성화할지 여부,
 * false -> true는 바로 적용하고,
 * true -> false는 300ms 뒤에 클릭 이벤트가 활성화됨
 * @returns 클릭 이벤트를 비활성화할지 여부
 */
const useClickable = (willBeDisabled: boolean) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (willBeDisabled) {
      setIsDisabled(true);
      return;
    }

    setTimeout(() => {
      setIsDisabled(false);
    }, 300);
  }, [willBeDisabled]);

  return { isDisabled, setIsDisabled };
};

export default useClickable;
