import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';

import { Modal } from '@/components/Modal/Modal';
import { ModalKind, ModalProps } from '@/components/Modal/ModalTypes';

type ModalContextData<T, R> = {
  showModal: (
    kind: ModalKind,
    callback?: R,
    isCloseOnOutOfFocusPrevented?: boolean,
  ) => void;
  hideModal: () => void;
  context: T;
  setContext: Dispatch<SetStateAction<T>>;
};

export type RenderModalsProps<T> = {
  hideModal: () => void;
  context: T;
  setContext: Dispatch<SetStateAction<T>>;
};

type RenderModals<T> = (
  props: RenderModalsProps<T>,
) => Array<ReactElement<ModalProps>>;

const createModalProvider = <T, R>(
  ModalContext: React.Context<ModalContextData<T, R>>,
  initial: T,
  renderModals: RenderModals<T>,
) => {
  const renderComponent = (
    kind: ModalKind,
    modals: Map<ModalKind, ReactElement>,
  ) => {
    return <>{modals.get(kind)}</>;
  };

  return ({ children }: PropsWithChildren) => {
    const modalsMap = new Map<ModalKind, ReactElement>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [kind, setKind] = useState<ModalKind | undefined>(undefined);
    const [context, setContext] = useState<T>(initial);
    const [isCloseOnOutOfFocusPrevented, setIsCloseOnOutOfFocusPrevented] =
      useState(false);

    const showModal: ModalContextData<T, R>['showModal'] = (
      kind,
      args,
      isCloseOnOutOfFocusPrevented,
    ) => {
      setKind(kind);
      setIsOpen(true);
      setIsCloseOnOutOfFocusPrevented(isCloseOnOutOfFocusPrevented || false);
      if (args) {
        setContext((prevState) => {
          return {
            ...prevState,
            args,
          };
        });
      }
    };

    const hideModal: ModalContextData<T, R>['hideModal'] = useCallback(() => {
      setKind(undefined);
      setIsOpen(false);
    }, []);

    const modals = renderModals({ hideModal, context, setContext });
    modals.forEach((modal) => {
      modalsMap.set(modal.props.kind, modal);
    });

    return (
      <ModalContext.Provider
        value={{ showModal, hideModal, context, setContext }}
      >
        {children}
        <Modal
          isOpen={isOpen}
          close={hideModal}
          isCloseOnOutOfFocusPrevented={isCloseOnOutOfFocusPrevented}
        >
          {isOpen && renderComponent(kind!, modalsMap)}
        </Modal>
      </ModalContext.Provider>
    );
  };
};

const createUseModal = <T, R>(
  ModalContext: React.Context<ModalContextData<T, R>>,
) => {
  return () => {
    const modalContext = useContext(ModalContext);
    if (modalContext === undefined) {
      throw new Error('useModal must be used within <ModalProvider />');
    }
    const { showModal, hideModal, context, setContext } = modalContext;

    return {
      showModal: showModal,
      hideModal: hideModal,
      context: context,
      setContext: setContext,
    };
  };
};

export const createModalContext = <T, R>(
  initial: T,
  renderModals: RenderModals<T>,
) => {
  const ModalContext = createContext<ModalContextData<T, R>>({
    showModal: () => {},
    hideModal: () => {},
    context: initial,
    setContext: () => {},
  });
  const ModalProvider = createModalProvider(
    ModalContext,
    initial,
    renderModals,
  );
  const useModal = createUseModal(ModalContext);

  return { ModalContext, ModalProvider, useModal };
};
