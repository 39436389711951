import { useResponsive } from '@/hooks';

interface ResponsivePageLayoutProps {
  desktop: React.ReactElement;
  mobile: React.ReactElement;
}

const ResponsivePageLayout = ({
  desktop,
  mobile,
}: ResponsivePageLayoutProps) => {
  const { isDesktop } = useResponsive();

  return isDesktop ? <>{desktop}</> : <>{mobile}</>;
};

export default ResponsivePageLayout;
