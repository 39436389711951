import { env } from '@/features/env';
import { init } from '@amplitude/analytics-browser';

export const initAmplitude = () => {
  if (typeof window === 'undefined') {
    return;
  }

  init(env.AMPLITUDE_KEY);
};
