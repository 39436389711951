import { useEffect, useRef } from 'react';

import { amplitudeEventNames, trackEvent } from '@/features/amplitude';

import { useSession } from 'next-auth/react';

type AuthStatus = 'authenticated' | 'unauthenticated';

export const useSigninDetection = () => {
  const { data: session, status } = useSession();
  const lastValidStatusRef = useRef<AuthStatus | undefined>(undefined); // loading이 아닌 마지막 유효 상태를 추적

  const currentStatus = status === 'loading' ? undefined : status;

  useEffect(() => {
    if (!currentStatus) {
      return;
    }

    // 이전 유효 상태가 없으면 현재 상태만 저장하고 리턴
    if (!lastValidStatusRef.current) {
      lastValidStatusRef.current = currentStatus;
      return;
    }

    if (lastValidStatusRef.current === currentStatus) {
      return;
    }

    // 상태가 실제로 변경되었을 때 콜백 실행
    if (
      lastValidStatusRef.current === 'unauthenticated' &&
      currentStatus === 'authenticated'
    ) {
      trackEvent(amplitudeEventNames.SIGNIN_COMPLETED, {
        type: session?.provider ?? 'unknown',
      });
    }

    // 현재 상태를 마지막 유효 상태로 업데이트
    lastValidStatusRef.current = currentStatus;
  }, [currentStatus, session]);

  return { status };
};
