import styles from './index.module.scss';

interface MobileHeaderProps {
  title: string;
}

const MobileHeader = ({ title }: MobileHeaderProps) => {
  return (
    <div className={styles.root}>
      <h1>{title}</h1>
    </div>
  );
};

export default MobileHeader;
