import clsx from 'clsx';

import styles from './index.module.scss';

interface DividerProps {
  type?: 'fill' | 'line';
  color?: 'n50' | 'n30' | 'n10' | 'black';
}

const Divider = ({ type = 'fill', color = 'black' }: DividerProps) => {
  return <div className={clsx(styles.root, styles[type], styles[color])} />;
};

export default Divider;
