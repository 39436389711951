export const GUIDE_URL = 'https://timepercentcorp.com/guide/guide_robot';

export const rootRoute = '/';

export const ONBOARDING_EXCHANGE_STEP1_ROUTE = '/onboarding/exchange/step1'; // 거래소 연결
export const ONBOARDING_EXCHANGE_STEP2_ROUTE = '/onboarding/exchange/step2'; // 코인원 계정
export const ONBOARDING_EXCHANGE_STEP3_ROUTE = '/onboarding/exchange/step3'; // 코인원 회원가입 전
export const ONBOARDING_EXCHANGE_STEP4_ROUTE = '/onboarding/exchange/step4'; // 코인원 로그인 전
export const ONBOARDING_EXCHANGE_STEP5_ROUTE = '/onboarding/exchange/step5'; // 거래소 연결 완료
export const ONBOARDING_FIRST_VISIT_ROUTE = '/onboarding/firstvisit'; // 첫 방문

export const ONBOARDING_TUTORIAL_STEP1_ROUTE = '/onboarding/tutorial/step1'; // 퀴즈 문제
export const ONBOARDING_TUTORIAL_STEP2_ROUTE = '/onboarding/tutorial/step2'; // 퀴즈 정답O
export const ONBOARDING_TUTORIAL_STEP3_ROUTE = '/onboarding/tutorial/step3'; // 퀴즈 정답X
export const ONBOARDING_TUTORIAL_STEP4_ROUTE = '/onboarding/tutorial/step4'; // 튜토리얼 시작 전
export const ONBOARDING_TUTORIAL_STEP5_ROUTE = '/onboarding/tutorial/step5'; // 튜토리얼 1_1 ~ 3_2
export const ONBOARDING_TUTORIAL_STEP6_ROUTE = '/onboarding/tutorial/step6'; // 튜토리얼 4
export const ONBOARDING_TUTORIAL_STEP7_ROUTE = '/onboarding/tutorial/step7'; // 튜토리얼 5 ~ 8_3

export const companyRoute = 'http://timepercentcorp.com/';
export const termsRoute = '/policy/terms';
export const privacyRoute = '/policy/privacy';
export const homeRoute = '/home';
export const signInRoute = '/signin';
export const signInCompletedRoute = '/signin/completed'; // 로그인 이후
export const signUpRoute = '/signup';
export const blogRoute = '/blog';

export const backtestingRoute = '/backtesting';
export const backtestLoadingRoute = (taskId: string) =>
  `/backtesting/loading/${taskId}`;
export const backtestingNoResultRoute = (id: string) =>
  `/backtesting/none/${id}`;
export const strategyRoute = '/strategy';
export const marketplaceRoute = '/marketplace';
export const marketplaceRegisterStrategyRoute = '/marketplace/new';

export const marketplaceStrategyRoute = (backtestingId: string) =>
  `/marketplace/${backtestingId}`;

// export const myAssetRoute = (username: string) => `/user/${username}/asset`;

export const myStrategyRoute = (username: string) =>
  `/user/${username}/strategy`;

export const myStrategyDetailRoute = ({
  username,
  strategyId,
}: {
  username: string | null;
  strategyId: string;
}) => {
  return `/user/${username}/strategy/${strategyId}`;
};

export const myPageRoute = (username: string) => `/user/${username}`;

export const tradingrobotRoute = (username: string) =>
  `/user/${username}/tradingrobot`;

export const addTradingrobotRoute = (username: string) =>
  `/user/${username}/tradingrobot/new`;

export const tradingrobotDetailRoute = ({
  username,
  robotId,
}: {
  username: string;
  robotId: string;
}) => `/user/${username}/tradingrobot/${robotId}`;

export const myAssetPageRoute = (username: string) => `/user/${username}/asset`;

export const noAuthPages = [
  rootRoute,
  homeRoute,
  signInRoute,
  signUpRoute,
  privacyRoute,
  termsRoute,
  blogRoute,
  ONBOARDING_FIRST_VISIT_ROUTE,
  '/callback/naver',
  '/callback/google',
  '/callback/coinone',
  '/callback/apple',
  '/callback/coinone',
  '/callback/credentials',
  '/_next/image',
  '/_next/image/',
  '/401',
  '/404',
];
