export const amplitudeEventNames = {
  BTN_SESSION_START: 'btn_session_start',
  BTN_APP_DOWNLOAD: 'btn_app_download',
  H_TB_LOGO: 'h_tb_logo',
  H_MENU_STRTG: 'h_menu_strtg',
  H_MENU_MARKET: 'h_menu_market',
  H_MENU_BT: 'h_menu_bt',
  H_MENU_BT_MARKETPLACE: 'h_menu_bt_marketplace',
  H_MENU_BT_BLOG: 'h_menu_bt_blog',
  H_MENU_CS: 'h_menu_cs',
  H_MENU_COMMUNITY: 'h_menu_community',
  H_MENU_MYPAGE: 'h_menu_mypage',
  H_MENU_ASSET: 'h_menu_asset',
  H_MENU_ROBOT: 'h_menu_robot',
  BTN_ACCOUNT_SIGNOUT: 'btn_account_signout',
  OC_GENERAL_PANEL: 'oc_general_panel',
  OC_SELECTPAIR_PANEL: 'oc_selectpair_panel',
  OC_BUY_STRATEGY_PANEL: 'oc_buy_strategy_panel',
  OC_SELL_STRATEGY_PANEL: 'oc_sell_strategy_panel',
  INPUT_BT_START_DATE: 'input_bt_start_date',
  INPUT_BT_END_DATE: 'input_bt_end_date',
  BTN_SELECT_PAIR_MODAL_OPEN: 'btn_select_pair_modal_open',
  BTN_FORMULA_SELECT: 'btn_formula_select',
  BTN_FORMULA_DELETE: 'btn_formula_delete',
  BTN_FORMULA_ADD: 'btn_formula_add',
  BTN_BUY_PREVIEW: 'btn_buy_preview',
  BTN_SELL_PREVIEW: 'btn_sell_preview',
  BTN_BT_CANCEL: 'btn_bt_cancel',
  BTN_BT_START: 'btn_bt_start',
  BTN_BT_EDIT: 'btn_bt_edit',
  TAB_SUMMARY: 'tab_summary',
  TAB_ANALYSIS: 'tab_analysis',
  TAB_ROBOT_RESULT: 'tab_robot_result',
  TAB_ROBOT_MARKETS: 'tab_robot_markets',
  TAB_ROBOT_TRANSACTIONS: 'tab_robot_transactions',
  TAB_ROBOOT_HISTORY: 'tab_roboot_history',
  FORMULA_GUIDE: 'formula_guide',
  HOME_BTN_START: 'home_btn_start',
  BTN_STRATEGY_CALL: 'btn_strategy_call',
  BTN_BACKTESTING_FAIL: 'btn_backtesting_fail',
  BTN_APPLY_CHART: 'btn_apply_chart',
  BTN_BUY_PREVIEW_CANCEL: 'btn_buy_preview_cancel',
  BTN_SELL_PREVIEW_CANCEL: 'btn_sell_preview_cancel',
  BTN_ACCOUNT_START_DELETE: 'btn_account_start_delete',
  BTN_ACCOUNT_DELETE_CANCEL: 'btn_account_delete_cancel',
  BTN_ACCOUNT_DELETE: 'btn_account_delete',
  BTN_MARKETPLACE_ADD_START: 'btn_marketplace_add_start',
  BTN_MARKETPLACE_SELECT: 'btn_marketplace_select',
  BTN_MARKETPLACE_DELETE: 'btn_marketplace_delete',
  BTN_MARKETPLACE_SELECT_BT: 'btn_marketplace_select_bt',
  BTN_MARKETPLACE_ADD: 'btn_marketplace_add',
  STRG_DETAIL: 'strg_detail',
  STRG_DELETE: 'strg_delete',
  STRG_EDIT: 'strg_edit',
  ASSET_TAB: 'asset_tab',
  BTN_SELECT_PAIR_MODAL_CLOSE: 'btn_select_pair_modal_close',
  BTN_EXCHANGE_CONNECT: 'btn_exchange_connect',
  BTN_COINONE_SIGNIN: 'btn_coinone_signin',
  BTN_COINONE_SIGNUP: 'btn_coinone_signup',
  BTN_ACCOUNT_SIGNIN: 'btn_account_signin',
  BTN_ACCOUNT_SIGNUP: 'btn_account_signup',
  BTN_ROBOT_GUIDE: 'btn_robot_guide',
  BTN_ROBOT_ADD_START: 'btn_robot_add_start',
  BTN_PAPERROBOT_ADD_START: 'btn_paperrobot_add_start',
  BTN_ROBOT_DETAIL: 'btn_robot_detail',
  BTN_ROBOT_SELECT_BT: 'btn_robot_select_bt',
  BTN_ROBOT_AGREE: 'btn_robot_agree',
  BTN_ROBOT_ADD: 'btn_robot_add',
  BTN_PAPERROBOT_ADD: 'btn_paperrobot_add',
  BTN_ROBOT_START: 'btn_robot_start',
  BTN_PAPERROBOT_START: 'btn_paperrobot_start',
  BTN_ROBOT_STOP: 'btn_robot_stop',
  BTN_PAPERROBOT_STOP: 'btn_paperrobot_stop',
  BTN_ROBOT_DELETE: 'btn_robot_delete',
  BTN_MARKETPLACE_FOLLOW: 'btn_marketplace_follow',
  BTN_MARKETPLACE_UNFOLLOW: 'btn_marketplace_unfollow',
  BTN_RELOAD: 'btn_reload',
  BTN_TOOLTIP: 'btn_tooltip',
  CLICK_FAQ: 'click_FAQ',
  BTN_DCA_TOGGLE: 'btn_dca_toggle',
  BTN_MAX_STEPS: 'btn_max_steps',
  BTN_SPLIT_RATIO: 'btn_split_ratio',
  BTN_MOBILE_START: 'btn_mobile_start',
  BTN_EXCHANGE_DONE: 'btn_exchange_done',
  BTN_TUTORIAL_1: 'btn_tutorial_1',
  BTN_TUTORIAL_2: 'btn_tutorial_2',
  BTN_TUTORIAL_3: 'btn_tutorial_3',
  BTN_TUTORIAL_4: 'btn_tutorial_4',
  BTN_TUTORIAL_5: 'btn_tutorial_5',
  BTN_TUTORIAL_6: 'btn_tutorial_6',
  BTN_TUTORIAL_7: 'btn_tutorial_7',
  BTN_PUSH_GOTOSETTING: 'btn_push_goto_setting',
  BTN_PUSH_CLOSE: 'btn_push_close',
  BTN_SKIP_QUIZ: 'btn_skip_quiz',
  SIGNIN_COMPLETED: 'signin_completed',
  AMPLITUDE_FIRST_SESSION_STARTED: 'Amplitude First Session Started!',
  BTN_MOBILE_APP_DOWNLOAD_1: 'btn_mobile_app_download_1',
  BTN_MOBILE_APP_DOWNLOAD_2: 'btn_mobile_app_download_2',
  BTN_MOBILE_APP_DOWNLOAD_DISMISSED_1: 'btn_mobile_app_download_dismissed_1',
  BTN_MOBILE_APP_DOWNLOAD_DISMISSED_2: 'btn_mobile_app_download_dismissed_2',
  BTN_FILTER_CLICK: 'btn_filter_click',
  BTN_FILTER_STYLE: 'btn_filter_style',
  BTN_FILTER_MARKET: 'btn_filter_market',
  BTN_FILTER_MINIMUM: 'btn_filter_minimum',
  BTN_FILTER_APPLY: 'btn_filter_apply',
  BTN_SORT_CLICK: 'btn_sort_click',
  BTN_SORT_LATEST: 'btn_sort_latest',
  BTN_SORT_SCORE: 'btn_sort_score',
  BTN_SORT_RETURN: 'btn_sort_return',
  BTN_SORT_TRANSACTIONAMOUNT: 'btn_sort_transactionamount',
  BTN_SORT_PNL: 'btn_sort_pnl',
  BTN_SORT_APPLY: 'btn_sort_apply',
};

export const amplitudeEventProperties = {
  date: 'date',
  formula_select_index: 'formula_select_index',
  formula_select_position: 'formula_select_position',
  sorting_value: 'sorting_value',
};
