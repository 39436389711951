import CheckIcon from '@/images/icons/ic_check-circle_p50_28px.svg';
import clsx from 'clsx';

import styles from './index.module.scss';

interface MenuItemProps {
  value: string;
  theme: 'neutral1' | 'neutral2' | 'neutral3';
  isSelected: boolean;
  onClick: () => void;
}

const MenuItem = ({ value, theme, isSelected, onClick }: MenuItemProps) => {
  return (
    <li
      role="menuitem"
      className={clsx(
        styles.item,
        styles[theme],
        isSelected && styles.selected,
      )}
      onClick={onClick}
    >
      {value}
      {isSelected && <CheckIcon />}
    </li>
  );
};

const Menu = ({ children }: { children: React.ReactNode }) => {
  return (
    <ul role="menu" className={styles.root}>
      {children}
    </ul>
  );
};

Menu.Item = MenuItem;

export default Menu;
