import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

export const SUPPORTED_CRYPTOS = [
  'BTC',
  'ETH',
  'DOGE',
  'SOL',
  'TRX',
  'XRP',
  'ADA',
];
export interface Price {
  [x: string]: string | number;
}

export const getCryptoCurrentPrice = async (market: string) => {
  // https://docs.coinone.co.kr/reference/chart
  try {
    const response = await axios.get(
      `https://api.coinone.co.kr/public/v2/chart/KRW/${market}`,
      {
        params: {
          interval: '1m',
        },
      },
    );

    if (!response.data.chart) {
      return null;
    }

    return response.data.chart[0].close;
  } catch (e) {
    Sentry.captureMessage('failed to getCryptoCurrentPrice', {
      level: 'info',
      extra: { error: e },
    });

    return null;
  }
};
