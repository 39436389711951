import { useState } from 'react';

import ScrollToTopButton from '@/components/Button/ScrollToTopButton';
import TermWithValue from '@/components/Content/TermWithValue';
import Title from '@/components/Content/Title';
import Divider from '@/components/Divider';
import {
  SortOrderSheet,
  StrategyFilterSheet,
} from '@/components/Mobile/BottomSheets';
import MobileHeader from '@/components/Mobile/Header';
import { MobileStrategyPageTab } from '@/components/Mobile/MyStrategy';
import MarketStrategyCard from '@/components/Mobile/components/MarketStrategyCard';
import Spinner from '@/components/Spinner';
import { routes } from '@/constants/';
import { amplitudeEventNames, trackEvent } from '@/features/amplitude';
import { PublicStrategyOverview } from '@/features/api/chart/strategy/type';

import { SUPPORTED_CRYPTOS } from '@/features/api/coinone/getCryptoCurrentPrice';
import { formatCurrency } from '@/features/utils';
import { useClickAway, useSafeRouter } from '@/hooks';
import useClickable from '@/hooks/useClickable';
import strategyQuery from '@/hooks/useQuery/strategy';
import FilterInactiveIcon from '@/images/icons/ic_filter_n50_24px.svg';
import FilterActiveIcon from '@/images/icons/ic_filter_n80_24px.svg';
import FireIcon from '@/images/icons/ic_fire.svg';
import MeatBallsIcon from '@/images/icons/ic_meatballs.svg';
import SortIcon from '@/images/icons/ic_sort.svg';

import clsx from 'clsx';

import styles from './MobileMarketplacePage.module.scss';

export const sortOrders = [
  '최신순',
  '점수 높은 순',
  '최근 3개월 수익률 높은 순',
  '누적 거래대금 높은 순',
  '누적 손익 높은 순',
] as const;

export const styleFilters = [
  '하이리스크 하이리턴',
  '로우리스크 로우리턴',
] as const;
export const marketFilters = [...SUPPORTED_CRYPTOS] as const;
export const minPrincipalFilters = [
  '100만 원 미만',
  '100만 원 이상 300만 원 미만',
  '300만 원 이상 1000만 원 미만',
  '1000만 원 이상',
] as const;

const MobileMarketplacePage = () => {
  const { safePush } = useSafeRouter();
  const {
    data: strategyPerformanceReport,
    isSuccess: isStrategyPerformanceReportSuccess,
  } = strategyQuery.useStrategyPerformanceReport();
  const { isLoading: isStrategiesLoading, data: allPublicStrategies = [] } =
    strategyQuery.useAllPublicStrategies();

  const [selectedSortOrder, setSelectedSortOrder] =
    useState<(typeof sortOrders)[number]>('최신순');
  const [selectedStyleFilters, setSelectedStyleFilters] = useState<
    Array<(typeof styleFilters)[number]>
  >([]); // 하나만 선택 가능
  const [selectedMarketFilters, setSelectedMarketFilters] = useState<
    Array<(typeof marketFilters)[number]>
  >([]); // 여러 개 선택 가능
  const [selectedMinPrincipalFilters, setSelectedMinPrincipalFilters] =
    useState<Array<(typeof minPrincipalFilters)[number]>>([]); // 하라만 선택 가능

  const [isSortOrderSheetOpen, setIsSortOrderSheetOpen] = useState(false);
  const [isStrategyFilterSheetOpen, setIsStrategyFilterSheetOpen] =
    useState(false);
  const { isDisabled: isMainAreaDisabled } = useClickable(
    isSortOrderSheetOpen || isStrategyFilterSheetOpen,
  );

  const hotStrategies = allPublicStrategies.filter((strategy) =>
    strategy.labels.includes('HOT'),
  );

  const sortOrderSheetRef = useClickAway(() => {
    setIsSortOrderSheetOpen(false);
  });

  const strategyFilterSheetRef = useClickAway(() => {
    setIsStrategyFilterSheetOpen(false);
  });

  const isAtLeastOneFilterSelected =
    selectedStyleFilters.length > 0 ||
    selectedMarketFilters.length > 0 ||
    selectedMinPrincipalFilters.length > 0;

  const filterByStyle = (strategy: PublicStrategyOverview) => {
    if (selectedStyleFilters.includes('하이리스크 하이리턴')) {
      return strategy.mdd > 20;
    }

    if (selectedStyleFilters.includes('로우리스크 로우리턴')) {
      return strategy.mdd <= 20;
    }

    return true;
  };

  const filterByMarket = (strategy: PublicStrategyOverview) => {
    if (selectedMarketFilters.length === 0) {
      return true;
    }

    return selectedMarketFilters.includes(
      strategy.markets[0].replace('KRW-', ''),
    );
  };

  const filterByMinPrincipal = (strategy: PublicStrategyOverview) => {
    if (selectedMinPrincipalFilters.length === 0) {
      return true;
    }

    const minPrincipalFilter = selectedMinPrincipalFilters[0];
    const minPrincipal = strategy.min_principal ?? 0;

    if (minPrincipalFilter === '100만 원 미만') {
      return minPrincipal < 1000000;
    }

    if (minPrincipalFilter === '100만 원 이상 300만 원 미만') {
      return minPrincipal >= 1000000 && minPrincipal < 3000000;
    }

    if (minPrincipalFilter === '300만 원 이상 1000만 원 미만') {
      return minPrincipal >= 3000000 && minPrincipal < 10000000;
    }

    if (minPrincipalFilter === '1000만 원 이상') {
      return minPrincipal >= 10000000;
    }

    return true;
  };

  const filteredStrategies = isAtLeastOneFilterSelected
    ? allPublicStrategies
        .filter((strategy) => filterByStyle(strategy))
        .filter((strategy) => filterByMarket(strategy))
        .filter((strategy) => filterByMinPrincipal(strategy))
    : allPublicStrategies;

  const sortedStrategies = filteredStrategies.sort((a, b) => {
    if (selectedSortOrder === '점수 높은 순') {
      return b.score - a.score;
    }

    if (selectedSortOrder === '최근 3개월 수익률 높은 순') {
      return b.latest_ror - a.latest_ror;
    }

    if (selectedSortOrder === '누적 거래대금 높은 순') {
      return b.cumulative_trading_volume - a.cumulative_trading_volume;
    }

    if (selectedSortOrder === '누적 손익 높은 순') {
      return b.cumulative_trading_pnl - a.cumulative_trading_pnl;
    }

    // 기본값, 최신순
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  const handleCardClick = (strategy: PublicStrategyOverview) => {
    const { mdd, ror, name, strategy_id: strategyId } = strategy;

    trackEvent(amplitudeEventNames.STRG_DETAIL, {
      mdd,
      ror,
      value: name,
    });

    safePush(routes.marketplaceStrategyRoute(strategyId));
  };

  const handleSortOrderClick = () => {
    trackEvent(amplitudeEventNames.BTN_SORT_CLICK);
    setIsSortOrderSheetOpen(true);
  };

  const handleFilterClick = () => {
    trackEvent(amplitudeEventNames.BTN_FILTER_CLICK);
    setIsStrategyFilterSheetOpen(true);
  };

  const handleSheetCloseClick = () => {
    setIsSortOrderSheetOpen(false);
    setIsStrategyFilterSheetOpen(false);
  };

  return (
    <div className={styles.root}>
      <MobileHeader title="전략" />
      <main
        className={clsx(styles.main, isMainAreaDisabled && styles.disabled)}
      >
        <MobileStrategyPageTab />
        <div className={styles.body}>
          <div className={styles.section1}>
            <Title text="실시간 거래 리포트" size="medium" />
            <div className={styles.contentWrap}>
              <div className={styles.content}>
                <TermWithValue
                  term={
                    <>
                      지금 투자 중인 금액
                      <FireIcon />
                    </>
                  }
                  value={
                    isStrategyPerformanceReportSuccess
                      ? `${formatCurrency(strategyPerformanceReport.trading_volume)}원`
                      : '-'
                  }
                  termTextStyle="caption"
                  valueTextStyle="label1"
                />
              </div>
              {/* TODO: TB-6659 코멘트 참고 */}
              {/* <div className={styles.content}>
                <TermWithValue
                  term={'누적 손익'}
                  value={strategyPerformanceReport?.cumulative_trading_pnl ?? 0}
                  displayValue={
                    isStrategyPerformanceReportSuccess
                      ? `${signedFormatter(strategyPerformanceReport.cumulative_trading_pnl, true, true)}원`
                      : '-'
                  }
                  hasSign
                  termTextStyle="caption"
                  valueTextStyle="label1"
                />
              </div> */}
              <div className={styles.content}>
                <TermWithValue
                  term={'누적 거래대금'}
                  value={
                    isStrategyPerformanceReportSuccess
                      ? `${formatCurrency(strategyPerformanceReport.cumulative_trading_volume)}원`
                      : '-'
                  }
                  termTextStyle="caption"
                  valueTextStyle="label1"
                />
              </div>
              <div className={styles.content}>
                <TermWithValue
                  term={'누적 실전 투자자'}
                  value={
                    isStrategyPerformanceReportSuccess
                      ? `${strategyPerformanceReport.cumulative_traders}명`
                      : '-'
                  }
                  termTextStyle="caption"
                  valueTextStyle="label1"
                />
              </div>
            </div>
          </div>
          <div className={styles.section2}>
            <Title text="지금 많이 보고 있는 전략" size="medium" />
            {hotStrategies.map((strategy) => (
              <li
                key={strategy.id}
                className={styles.card}
                onClick={() => handleCardClick(strategy)}
              >
                <MarketStrategyCard strategy={strategy} />
              </li>
            ))}
            {isStrategiesLoading && (
              <li className={styles.loading}>
                <Spinner />
              </li>
            )}
          </div>
          <Divider type="line" color="n30" />
          <div className={styles.section3}>
            <div className={styles.sortWithFilter}>
              <button className={styles.left} onClick={handleSortOrderClick}>
                <SortIcon />
                {selectedSortOrder}
              </button>
              <button className={styles.right} onClick={handleFilterClick}>
                {isAtLeastOneFilterSelected ? (
                  <>
                    <div className={styles.filterAmount}>
                      {selectedStyleFilters.length +
                        selectedMarketFilters.length +
                        selectedMinPrincipalFilters.length}
                    </div>
                    <FilterActiveIcon />
                  </>
                ) : (
                  <FilterInactiveIcon />
                )}
              </button>
            </div>
            {sortedStrategies.length > 0 ? (
              <ol className={styles.strategies}>
                {sortedStrategies.map((strategy) => (
                  <li
                    key={strategy.id}
                    className={styles.card}
                    onClick={() => handleCardClick(strategy)}
                  >
                    <MarketStrategyCard strategy={strategy} />
                  </li>
                ))}
                {isStrategiesLoading && (
                  <li className={styles.loading}>
                    <Spinner />
                  </li>
                )}
              </ol>
            ) : (
              <div className={styles.noResult}>
                <MeatBallsIcon />
                <p className={styles.noResultTitle}>
                  해당 조건을 만족하는 전략이 없어요.
                </p>
                <p className={styles.noResultDescription}>
                  필터를 변경해 보세요.
                </p>
              </div>
            )}
          </div>
        </div>
      </main>
      {!isSortOrderSheetOpen && !isStrategyFilterSheetOpen && (
        <ScrollToTopButton />
      )}
      <SortOrderSheet
        ref={sortOrderSheetRef}
        isOpen={isSortOrderSheetOpen}
        onClose={handleSheetCloseClick}
        selectedSortOrder={selectedSortOrder}
        setSortOrder={setSelectedSortOrder}
      />
      <StrategyFilterSheet
        ref={strategyFilterSheetRef}
        isOpen={isStrategyFilterSheetOpen}
        onClose={handleSheetCloseClick}
        selectedStyleFilters={selectedStyleFilters}
        selectedMarketFilters={selectedMarketFilters}
        selectedMinPrincipalFilters={selectedMinPrincipalFilters}
        setSelectedStyleFilters={setSelectedStyleFilters}
        setSelectedMarketFilters={setSelectedMarketFilters}
        setSelectedMinPrincipalFilters={setSelectedMinPrincipalFilters}
      />
    </div>
  );
};

export default MobileMarketplacePage;
