import { useHighlightChange } from '@/hooks';
import clsx from 'clsx';

import styles from './index.module.scss';

type TextStyle =
  | 'headline1'
  | 'headline2'
  | 'title2'
  | 'body1'
  | 'body2'
  | 'label1'
  | 'label2'
  | 'caption';

interface TermWithValueProps {
  term: string | React.ReactNode;
  value: string | number;
  hasSign?: boolean;
  displayValue?: string | number;
  unit?: string;
  termTextStyle: TextStyle;
  valueTextStyle: TextStyle;
  valueTextColor?: string;
  direction?: 'horizontal' | 'vertical';
  isCenterAligned?: boolean;
  tag?: JSX.Element;
  hasTooltip?: boolean;
  tooltipId?: string;
  onClick?: () => void;
  hasLink?: boolean;
  hasChangableValue?: boolean;
}

const TermWithValue = ({
  term,
  value,
  hasSign,
  displayValue = value,
  termTextStyle,
  valueTextStyle,
  valueTextColor = 'n80',
  direction = 'vertical',
  isCenterAligned = false,
  tag,
  hasTooltip = false,
  tooltipId = undefined,
  onClick = undefined,
  hasLink = false,
  hasChangableValue = false,
}: TermWithValueProps) => {
  const highlightCss = useHighlightChange(value);

  return (
    <dl
      className={clsx(
        styles.root,
        styles[direction],
        isCenterAligned && styles.centerAligned,
      )}
      onClick={onClick}
    >
      <dt
        className={clsx(
          styles.term,
          styles[termTextStyle],
          hasTooltip && styles.withTooltip,
        )}
        data-tooltip-id={tooltipId}
      >
        {term}
      </dt>
      <dd
        className={clsx(
          styles.value,
          styles[valueTextStyle],
          styles[valueTextColor],
        )}
      >
        <div
          className={clsx(
            hasSign && (Number(value) >= 0 ? 'positive' : 'negative'),
            hasLink && styles.link,
            hasChangableValue && highlightCss,
          )}
        >
          {displayValue}
        </div>
        {tag}
      </dd>
    </dl>
  );
};

export default TermWithValue;
