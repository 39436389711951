import { useState } from 'react';

import CheckCircle from '@/components/CheckCircle/CheckCircle';
import { ConfirmModal } from '@/components/Modal/ConfirmModal/ConfirmModal';
import { SimpleModalProps } from '@/components/Modal/ModalTypes';

import styles from '@/components/Modal/ConfirmAgreeTermModal/ConfirmAgreeTermModal.module.scss';

export const AgreementTerms = () => {
  return (
    <>
      <div className={styles.description}>
        {`트레이딩뱅크 자동 투자 봇(이하 "트레이딩로봇")을 사용하기 앞서 아래의 위험 고지 및 유의 사항을 충분히 이해하시고, 이용 동의를 하셔야만 해당 기능을 이용하실 수 있습니다. 사용법에 대한 도움말을 반드시 숙지하신 후 소액 투자를 통해 전략에 대한 안정적인 수익을 충분히 검증하신 후 트레이딩로봇을 실행하시기를 권장 드립니다.`}
      </div>
      <div className={styles.termContainer}>
        <strong className={styles.sectionTitle}>1. 위험고지</strong>
        <p>
          트레이딩로봇은 회원님께 절대적인 투자수익을 보장하지 아니하며,
          투자손실을 초래할 수 있습니다. 해당 프로그램의 실행에 대한 충분한
          이해와 숙지 없이 거래를 하실 경우 발생하는 손실과 시스템 상 오류로
          인한 손실은 모두 고객님께 귀속됨을 유의하시기 바랍니다. 사용 전
          유의사항 및 도움말을 반드시 필독하시고 매매 시 착오가 발생하지 않도록
          유념하시기 바랍니다. 코인원 계정 연결(OAuth)를 통한 트레이딩뱅크 이용
          시 보유하신 코인원 자산이 트레이딩뱅크 투자 전략 실행에 따라 자동으로
          거래 될 수 있으므로 이용에 유의 하시기 바랍니다.
        </p>
        <strong className={styles.sectionTitle}>
          2. 트레이딩뱅크 트레이딩로봇이란?
        </strong>
        <p>
          ㈜타임퍼센트가 제공하는 트레이딩뱅크를 이용해 트레이딩로봇을 생성한 후
          트레이딩로봇에 등록된 전략이 생성하는 매매예정종목 및 감시조건에 따라
          해당 조건이 만족하는 시점에 주문을 실행하는 주문 프로그램을 말합니다.
        </p>
        <strong className={styles.sectionTitle}>3. 주문방식 </strong>
        <p>
          사용자가 저장한 감시조건에 따라 감시를 시작한 후 감시조건에 만족될
          경우 별도의 확인 없이 타임퍼센트 서버에서 자동으로 주문을 전송하는
          기능입니다.
        </p>
        <strong className={styles.sectionTitle}>
          4. 트레이딩로봇 사용 시 유의사항
        </strong>
        <ol>
          <li>
            트레이딩로봇 페이지에서 나가거나, 컴퓨터를 종료해도 시세감시 및
            자동주문이 지속해서 실행되니 유의하시기 바랍니다.
          </li>
          <li>
            트레이딩로봇 작동은 동시에 1개 전략까지 가능합니다. 작동 가능 로봇
            개수 확장은 현재 개발 중입니다.
          </li>
          <li>
            시세 급변 및 주문폭주 시 시세전송지연, 시세누락 등으로 인하여 주문
            미처리 및 미체결 등의 오류가 발생하거나 원하지 않은 호가에
            주문처리가 될 수도 있습니다. 감시조건이 만족한 시점에 주문설정에
            따라 다시 가격을 조회하므로 시세 급변 시에는 감시조건을 만족한
            가격과 시차가 생길 수 있으며, 빈 호가로 인해 원하지 않는 가격에
            주문이 실행될 수 있습니다.
          </li>
          <li>트레이딩로봇은 1분봉 단위로 작동합니다.</li>
          <li>
            트레이딩뱅크는 시스템의 안정화를 위해 매일 새벽 3시에 서버를
            재시작합니다. 이 시간 동안에는 전략에 따른 주문이 실행되지 않으니
            유의하시기 바랍니다.
          </li>
          <li>
            만기주문의 매도 주문이 체결되지 않는 경우, 매도 주문의 전량이 체결될
            때까지 1분봉마다 계속 매도 주문이 자동 실행됩니다.
          </li>
          <li>
            전략을 통한 매매가 아닌 직접 매매를 했을 경우 수익률 표기상 왜곡이
            발생하거나, 트레이딩로봇 작동이 중지될 수 있습니다.
          </li>
          <li>
            트레이딩뱅크를 통한 매매 거래는 코인원 리퍼럴 정산 대상에서 제외
            됩니다.
          </li>
          <li>
            트레이딩뱅크는 원활한 사용 환경을 위해 트레이딩로봇 관련 값들을
            반올림 또는 내림하여 표시하고 있으니 사용에 참고하시기 바랍니다.
          </li>
        </ol>
        <strong className={styles.sectionTitle}>
          5. 가상자산 거래에 관한 위험 고지
        </strong>
        <p>
          가상자산거래는 손실에 대한 위험이 매우 클 수 있으므로 회원님은
          가상자산 거래시 본인의 투자목적, 재산상황, 거래(투자)경험 등을
          감안하시고 아래 유의사항을 충분히 인지 후 거래 하시기 바랍니다.
        </p>
        <p>[ 가상자산 투자 유의사항 ]</p>
        <ol>
          <li>
            가상자산은 법정화폐가 아니므로 특정 주체가 가치를 보장하지 않습니다.
          </li>
          <li>
            가상자산은 365일 24시간 전 세계에서 거래되며, 시장의 수요 및 공급,
            각 가상자산의 정책, 국가별 법령 및 제도, 네트워크 상황 등 다양한
            요인으로 급격한 시세 변동이 발생할 수 있습니다.
          </li>
          <li>
            가상자산은 가격 변동폭에 제한이 없으므로, 원금손실 가능성이 있음을
            특히 유의하시기 바랍니다.
          </li>
          <li>
            가상자산은 초고위험 상품으로 투자자 자기책임 원칙이 우선되는 만큼,
            고객님이 투자하려는 가상자산의 정보를 백서 또는 평가보고서 등을 통해
            충분히 확인한 후에 신중히 투자 결정하시기 바랍니다.
          </li>
        </ol>
        <p>
          위 사항들은 가상자산 거래에 수반되는 위험 등에 대해 고객님이 알아야 할
          사항을 간략하게 서술한 것으로 가상자산 거래와 관련된 모든 위험을 기술
          한 것은 아닙니다. 또한 본 고지 내용은 거래소의 이용약관이나 국내외
          관련법규 등에 우선하지 못한다는 점을 양지하여 주시기 바랍니다.
        </p>
        <strong className={styles.sectionTitle}>6. 손해보상 및 면책조항</strong>
        <ol>
          <li>
            디지털자산의 가격은 시장상황과 디지털자산 관련된 각종 이슈, 각국의
            규제 도입 등 시장 내외부의 요소에 큰 변동성을 가지므로,
            트레이딩뱅크를 이용한 디지털자산 투자행위에서 손실이 발생할 수
            있으며, 투자행위에 대한 최종 판단 및 결정은 회원 본인이 함으로서
            그에 대한 제반 손실 책임은 회원에게 있으며 회사는 회원의 투자손실에
            대해 어떠한 책임도 지지않습니다.
          </li>
          <li>
            회사는 트레이딩뱅크를 활용한 회원의 디지털자산 투자가 디지털자산
            거래소의 시스템의 불완전한 상태나 운영, 회원의 귀책사유(회원의
            단말기, 네트워크 통신의 오류나 기능장애, 매뉴얼 미숙지, 조작 미숙)로
            인하여 발생하는 일체의 손해에 대해서 어떠한 책임을 지지 않습니다.
          </li>
          <li>
            회사는 무료로 제공되는 서비스 이용과 관련하여 어떠한 책임을 지지
            않습니다.
          </li>
          <li>
            회사는 전시, 사변, 천재지변, 국가비상사태, 해결이 곤란한 기술적
            결함, 디도스 공격, 기간통신사업자가 전기통신서비스를 중지하거나
            정상적으로 제공하지 아니하는 등 기타 불가항력 사유로 서비스를 제공할
            수 없는 경우에는 책임이 면제됩니다.
          </li>
          <li>
            회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 또는 테스트 목적의
            서비스 제공 중 개선과정이나 오류발생 등 부득이한 사유로 서비스가
            중지되거나 장애가 발생한 경우에 대해서는 책임이 면제됩니다.
          </li>
          <li>
            회사는 고객의 개별적인 디지털자산 투자행위에 개입하거나 디지털자산
            투자행위를 권유하지 않으며, 디지털자산에 대한 투자자문을 수행하지
            않습니다. 고객은 자신의 책임과 판단 하에 서비스 활용여부 및
            디지털자산 투자여부를 결정합니다.
          </li>
          <li>
            트레이딩로봇 사용 시 당사 프로그램 오류로 인한 문제를 인지하고
            회사에 즉시 통보하지 않고 프로그램 신호와 다르게 손실이 가중될 때
            어떠한 경우에도 책임지지 않습니다.
          </li>
        </ol>
      </div>
    </>
  );
};

const ConfirmAgreeTermModal = ({ onClose, onSubmit }: SimpleModalProps) => {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleCheckCircle = () => {
    setIsAgreed(!isAgreed);
  };

  return (
    <ConfirmModal
      title="트레이딩뱅크 실전 매매 연동 유의사항 및 이용 동의"
      onClose={onClose}
      buttonText="동의하기"
      onSubmit={onSubmit}
      isClickable={isAgreed}
    >
      <AgreementTerms />
      <div className={styles.agreeRow}>
        <CheckCircle checked={isAgreed} onChange={handleCheckCircle} />위
        유의사항을 모두 확인했습니다.
      </div>
    </ConfirmModal>
  );
};

export default ConfirmAgreeTermModal;
