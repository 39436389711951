import { useEffect } from 'react';

import { queryKeys } from '@/constants/';
import tradingApi from '@/features/api/chart/trading';
import { Tradingrobot } from '@/features/api/chart/trading/type';
import { queryClient } from '@/hooks/useQuery/base';

import * as Sentry from '@sentry/nextjs';

import { useSession } from 'next-auth/react';

export const useDeleteNeverStartedRobots = (
  neverStartedRobots: Tradingrobot[],
) => {
  const { data: session } = useSession();

  useEffect(() => {
    if (!session) {
      return;
    }

    if (neverStartedRobots.length === 0) {
      return;
    }

    const deleteRobot = async (id: string, name: string) => {
      Sentry.captureMessage('neverStartedRobots - deleteRobot', {
        level: 'info',
        extra: { id, name },
      });

      try {
        await tradingApi.deleteTrading(id);
        return { status: 'fulfilled', id, name };
      } catch (error) {
        Sentry.captureMessage('neverStartedRobots - deleteRobot error', {
          level: 'error',
          extra: { id, error },
        });
        return { status: 'rejected', id, name, error };
      }
    };

    const deleteNeverStartedRobots = async () => {
      try {
        const results = await Promise.allSettled(
          neverStartedRobots.map((robot) => deleteRobot(robot.id, robot.name)),
        );

        const failedDeletions = results.filter(
          (result) => result.status === 'rejected',
        );

        if (failedDeletions.length > 0) {
          Sentry.captureMessage(
            'neverStartedRobots - deleteNeverStartedRobots failedDeletions error',
            {
              level: 'warning',
              extra: { failedDeletions },
            },
          );
        }

        if (results.some((result) => result.status === 'fulfilled')) {
          queryClient.invalidateQueries([
            queryKeys.ALL_TRADING,
            { username: session.username },
          ]);
        }
      } catch (error) {
        Sentry.captureMessage(
          'neverStartedRobots - deleteNeverStartedRobots error',
          {
            level: 'error',
            extra: { error },
          },
        );
      }
    };

    deleteNeverStartedRobots();
  }, [neverStartedRobots, session]);
};
