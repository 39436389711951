import { createContext, useContext } from 'react';

import clsx from 'clsx';

import styles from './index.module.scss';

type PaginationContextProps = {
  maxPageCountPerView?: number;
  totalPageCount: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

type PaginationProps = PaginationContextProps & React.PropsWithChildren;

const PaginationContext = createContext({
  maxPageCountPerView: 1,
  totalPageCount: 1,
  currentPage: 1,
  setCurrentPage: (() => {}) as React.Dispatch<React.SetStateAction<number>>,
});

const PaginationWrapper = ({
  maxPageCountPerView = 5,
  totalPageCount,
  currentPage,
  setCurrentPage,
  children,
}: PaginationProps) => {
  const value = {
    maxPageCountPerView,
    totalPageCount,
    currentPage,
    setCurrentPage,
  };

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
};

const usePaginationContext = () => {
  return useContext(PaginationContext);
};

const PageButtons = () => {
  const { maxPageCountPerView, currentPage, setCurrentPage, totalPageCount } =
    usePaginationContext();

  const handleClick = (page: number) => {
    setCurrentPage(page);
  };

  const startIdx =
    Math.floor((currentPage - 1) / maxPageCountPerView) * maxPageCountPerView +
    1;
  const lastIdx = Math.min(
    Math.floor((currentPage - 1) / maxPageCountPerView) * maxPageCountPerView +
      maxPageCountPerView,
    totalPageCount,
  );

  const visiblePageNumbers = Array.from(
    { length: lastIdx - startIdx + 1 },
    (_, idx) => startIdx + idx,
  );

  return (
    <div className={styles.pageButtons}>
      {visiblePageNumbers.map((page) => {
        return (
          <button
            type="button"
            key={page}
            onClick={() => handleClick(page)}
            className={clsx(
              styles.pageButton,
              page === currentPage && styles.active,
            )}
          >
            {page}︎
          </button>
        );
      })}
    </div>
  );
};

const PrevButton = () => {
  const { currentPage, setCurrentPage, maxPageCountPerView } =
    usePaginationContext();

  const hasPrev = currentPage > maxPageCountPerView;

  const handleClick = () => {
    if (!hasPrev) {
      return;
    }

    setCurrentPage(Math.max(1, currentPage - maxPageCountPerView));
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={clsx(styles.prevButton, !hasPrev && styles.disabled)}
    >
      ◀︎
    </button>
  );
};

const NextButton = () => {
  const { currentPage, setCurrentPage, totalPageCount, maxPageCountPerView } =
    usePaginationContext();

  const hasNext =
    Math.ceil(currentPage / maxPageCountPerView) <
    Math.ceil(totalPageCount / maxPageCountPerView);

  const handleClick = () => {
    if (!hasNext) {
      return;
    }

    setCurrentPage(Math.min(currentPage + maxPageCountPerView, totalPageCount));
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={clsx(styles.nextButton, !hasNext && styles.disabled)}
    >
      ▶︎
    </button>
  );
};

const Pagination = ({
  maxPageCountPerView = 5,
  totalPageCount,
  currentPage,
  setCurrentPage,
}: PaginationProps) => {
  if (totalPageCount <= 1) {
    return null;
  }

  return (
    <PaginationWrapper
      maxPageCountPerView={maxPageCountPerView}
      totalPageCount={totalPageCount}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    >
      <div className={styles.root}>
        <PaginationWrapper.PrevButton />
        <PaginationWrapper.PageButtons />
        <PaginationWrapper.NextButton />
      </div>
    </PaginationWrapper>
  );
};

PaginationWrapper.PrevButton = PrevButton;
PaginationWrapper.PageButtons = PageButtons;
PaginationWrapper.NextButton = NextButton;

export default Pagination;
