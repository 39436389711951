import { memo } from 'react';

import Tag from '@/components/Content/Tag';
import TermWithValue from '@/components/Content/TermWithValue';
import Title from '@/components/Content/Title';

import Divider from '@/components/Divider';
import ScoreRangeIcon from '@/components/Icon/ScoreRangeIcon';

import { PublicStrategyOverview } from '@/features/api/chart/strategy/type';

import { formatCurrency, signedFormatter } from '@/features/utils';
import UncheckedStarIcon from '@/images/icons/ic_star_n50-40%_28px.svg';
import CheckedStarIcon from '@/images/icons/ic_star_p50_28px.svg';

import { useSession } from 'next-auth/react';

import styles from './index.module.scss';

interface StrategyCardProp {
  strategy: PublicStrategyOverview;
}

const MarketStrategyCard = ({ strategy }: StrategyCardProp) => {
  const { data: session } = useSession();
  const {
    is_following: isFollowing,
    author,
    name,
    score,
    markets,
    labels,
    latest_ror: latestRor,
    cumulative_trading_volume: cumulativeTradingVolume,
    // cumulative_trading_pnl: cumulativeTradingPnl,
  } = strategy;

  const isMine = session?.username === author;
  const market = markets[0].replace('KRW-', '');
  const isNew = labels.includes('NEW');

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <div className={styles.left}>
          <div className={styles.tags}>
            {isNew && <Tag text={'NEW'} size="small" theme="primary3" />}
            {market && <Tag text={market} size="small" theme="neutral2" />}
          </div>
          <Title text={name} size={'small'} />
        </div>
        <div className={styles.right}>
          {!isMine &&
            (isFollowing ? <CheckedStarIcon /> : <UncheckedStarIcon />)}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.section1}>
          <TermWithValue
            term="최근 3개월"
            value={latestRor}
            displayValue={`${signedFormatter(latestRor, true, true)}%`}
            hasSign
            termTextStyle="caption"
            valueTextStyle="title2"
            hasChangableValue
          />
          <TermWithValue
            term="점수"
            value={`${score}점`}
            termTextStyle="caption"
            valueTextStyle="title2"
            tag={
              <Tag
                text=""
                size="small"
                theme="neutral1"
                preIcon={<ScoreRangeIcon score={score} size="large" />}
              />
            }
          />
        </div>
        <Divider type="line" color="n50" />
        <div className={styles.section2}>
          <TermWithValue
            term="누적 거래대금"
            value={`${formatCurrency(cumulativeTradingVolume)}원`}
            termTextStyle="caption"
            valueTextStyle="caption"
            direction="horizontal"
          />
          {/* TODO: TB-6659 코멘트 참고 */}
          {/* <TermWithValue
            term="누적 손익"
            value={`${signedFormatter(cumulativeTradingPnl, false, true)}원`}
            termTextStyle="caption"
            valueTextStyle="caption"
            direction="horizontal"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default memo(MarketStrategyCard);
