import { useContext, useEffect, useState } from 'react';

import Button from '@/components/Button';
import Input from '@/components/Content/Input';
import TermWithValue from '@/components/Content/TermWithValue';
import Title from '@/components/Content/Title';
import Divider from '@/components/Divider';
import {
  AgreementSheet,
  BalanceNoticeSheet,
  RobotStartNoticeSheet,
  StrategiesSheet,
} from '@/components/Mobile/BottomSheets';
import GoBackButton from '@/components/Mobile/components/GoBackButton';
import { SnackbarContext } from '@/components/Snackbar';
import Spinner from '@/components/Spinner';
import { MAX_ACTIVE_ROBOTS_COUNT, routes } from '@/constants/';
import { amplitudeEventNames, trackEvent } from '@/features/amplitude';
import userApi from '@/features/api/account/user';
import { useClickAway, useNacho, useSafeRouter } from '@/hooks';
import tradingMutation from '@/hooks/useMutation/trading';
import tradingQuery from '@/hooks/useQuery/trading';
import BulbIcon from '@/images/icons/ic_bulb.svg';
import CaretDownIcon from '@/images/icons/ic_caret-down_n80_24px.svg';
import CaretRightIcon from '@/images/icons/ic_caret-right_n80_24px.svg';
import {
  cryptoExchange,
  MIN_PRINCIPAL,
} from '@/pages/user/[username]/tradingrobot/new';
import { useTradingrobotStore } from '@/store/tradingrobot';
import clsx from 'clsx';

import styles from '@/components/Mobile/Tradingrobot/New/MobileAddTradingrobot.module.scss';

export const AveragePrincipalBanner = () => {
  return (
    <div className={styles.banner}>
      <BulbIcon />
      <div>
        다른 투자자들은 평균{' '}
        <strong className={styles.strong}>2,217,371</strong>원을 투자해요.
      </div>
    </div>
  );
};

interface StartButtonProps {
  investment: number | null;
  currentAsset: number | null;
  setIsAgreementOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRobotStartNoticeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRobotId: React.Dispatch<React.SetStateAction<string | null>>;
}

const StartButton = ({
  investment,
  currentAsset,
  setIsAgreementOpen,
  setIsRobotStartNoticeOpen,
  setRobotId,
}: StartButtonProps) => {
  const { setSnackbarContents } = useContext(SnackbarContext);
  const { data: robots = [] } = tradingQuery.useAllTrading();
  const [isActivating, setIsActivating] = useState(false);
  const createTradingMutation = tradingMutation.useCreateTradingMutation();
  const startTradingMutation = tradingMutation.useStartTradingMutation();

  const {
    edittingRobot,
    actions: { setEdittingRobot },
  } = useTradingrobotStore();

  const activeRobotsCount = robots.filter((bot) => bot.state === 'UP').length;
  const isSameRobotAlreadyRunning = robots
    .filter((bot) => bot.state === 'UP')
    .some((bot) => bot.name === edittingRobot?.name);

  useEffect(() => {
    if (isActivating) {
      setTimeout(() => {
        setIsActivating(false);
      }, 3000);
    }
  }, [isActivating]);

  const validateConfig = () => {
    if (!edittingRobot) {
      setSnackbarContents({
        mode: 'error',
        text: '전략을 선택해주세요.',
      });

      return false;
    }

    if (
      !investment ||
      investment > Number(currentAsset) ||
      investment < MIN_PRINCIPAL ||
      investment < edittingRobot.min_principal
    ) {
      setSnackbarContents({
        mode: 'error',
        text: '유효한 투자금액을 입력해주세요.',
      });

      return false;
    }

    return true;
  };

  const handleStartClick = async () => {
    if (isActivating) {
      setSnackbarContents({
        mode: 'error',
        text: '다른 요청을 처리중입니다. 5초 후에 다시 시도해주세요.',
      });
      return;
    }

    if (isSameRobotAlreadyRunning) {
      setSnackbarContents({
        mode: 'error',
        text: '이미 같은 전략으로 작동 중인 로봇이 있어요. 중지 후 다시 시도해주세요.',
      });
      return;
    }

    if (!validateConfig()) {
      return;
    }

    if (!edittingRobot || !investment) {
      return false;
    }

    const termsStatus = await userApi.getTerms();

    if (termsStatus.robo_advisor === false) {
      setIsAgreementOpen(true);
      return;
    }

    trackEvent(amplitudeEventNames.BTN_ROBOT_START, {
      value: edittingRobot.name,
      exchange: cryptoExchange,
      amount: investment,
    });

    if (activeRobotsCount >= MAX_ACTIVE_ROBOTS_COUNT) {
      setSnackbarContents({
        mode: 'error',
        text: `트레이딩로봇은 ${MAX_ACTIVE_ROBOTS_COUNT}개씩 작동할 수 있어요. 작동 중인 로봇을 중지하고 새 로봇을 작동해주세요. `,
      });

      return;
    }

    try {
      setIsActivating(true);
      const createResponse = await createTradingMutation.mutateAsync({
        name: edittingRobot.name,
        exchange: cryptoExchange,
        market: edittingRobot.market,
        principal: investment,
        strategy_id: edittingRobot.strategy_id,
        robotName: edittingRobot.name,
      });

      if (createResponse.status === 201) {
        try {
          await startTradingMutation.mutateAsync({
            tradingId: createResponse.data,
            robotName: edittingRobot.name,
          });

          setRobotId(createResponse.data);
          setSnackbarContents({
            mode: 'success',
            text: '로봇 작동이 시작되었어요.',
          });
          setIsRobotStartNoticeOpen(true);
        } catch {
          setSnackbarContents({
            mode: 'error',
            text: '로봇 작동 시작에 실패했어요. 다시 시도해주세요.',
          });
        }
      }
    } catch {
      setSnackbarContents({
        mode: 'error',
        text: '로봇 작동 시작에 실패했어요. 다시 시도해주세요.',
      });
    } finally {
      setEdittingRobot(null);
    }
  };

  if (isActivating) {
    <div className={styles.buttons}>
      <Spinner />
    </div>;
  }

  return (
    <div className={styles.startButton}>
      <Button
        theme="neutral2"
        size="large"
        onClick={handleStartClick}
        isFullWidth
        isClickable={!isActivating}
      >
        로봇 작동하기
      </Button>
    </div>
  );
};

const MobileAddTradingrobotPage = () => {
  const { setPullToRefresh } = useNacho();
  const { router, safePush } = useSafeRouter();
  const username = router.query.username as string;

  const { edittingRobot } = useTradingrobotStore();

  const { data: currentAsset, isSuccess } =
    tradingQuery.useMyCurrentAssetToExchange({
      username,
    });

  const { data: reservedPrincipal } = tradingQuery.useReservedPrincipal({
    exchange: 'coinone',
  });

  const reservations = reservedPrincipal?.reservations ?? [];
  const balance = Number(reservedPrincipal?.balance) ?? 0;
  const robotsCashBalances = reservations.reduce(
    (acc, reservation) => acc + Number(reservation.cash_balance),
    0,
  );
  const availableBalance = Math.floor(balance - robotsCashBalances);

  const [robotId, setRobotId] = useState<string | null>(null);
  const [investment, setInvestment] = useState<number | null>(null);

  const [isAgreementOpen, setIsAgreementOpen] = useState(false);
  const [isStrategiesOpen, setIsStrategiesOpen] = useState(false);
  const [isRobotStartNoticeOpen, setIsRobotStartNoticeOpen] = useState(false);
  const [isBalanceNoticeOpen, setIsBalanceNoticeOpen] = useState(false);
  const [isBalanceBodyOpen, setIsBalanceBodyOpen] = useState(false);

  const agreementRef = useClickAway(() => {
    setIsAgreementOpen(false);
  });
  const strategiesSheetRef = useClickAway(() => {
    setIsStrategiesOpen(false);
  });
  const robotStartNoticeRef = useClickAway(() => {
    setIsRobotStartNoticeOpen(false);
  });
  const balanceNoticeRef = useClickAway(() => {
    setIsBalanceNoticeOpen(false);
  });

  useEffect(() => {
    if (isSuccess && (currentAsset === null || currentAsset < 1)) {
      setIsBalanceNoticeOpen(true);
    }
  }, [currentAsset, isSuccess]);

  useEffect(() => {
    if (isStrategiesOpen) {
      setPullToRefresh?.(false);
      return;
    }

    setPullToRefresh?.(true);
  }, [isStrategiesOpen, setPullToRefresh]);

  const handleStrategyInputClick = async () => {
    trackEvent(amplitudeEventNames.BTN_ROBOT_SELECT_BT);
    setIsStrategiesOpen((prev) => !prev);
  };

  const handleBottomSheetClose = () => {
    setIsStrategiesOpen(false);
    setIsAgreementOpen(false);
    setIsRobotStartNoticeOpen(false);
    setIsBalanceNoticeOpen(false);
  };

  const handleInvestmentChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const { value } = e.target;
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    setInvestment(sanitizedValue === '' ? null : parseInt(sanitizedValue));
  };

  const handleInvestmentBlur = () => {
    if (!investment) {
      return;
    }

    if (!isSuccess || currentAsset === null) {
      return;
    }

    if (investment > currentAsset) {
      setInvestment(Math.floor(currentAsset));
    }
  };

  const handleBalanceClick = () => {
    setIsBalanceBodyOpen((prev) => !prev);
  };

  const getInputFeedback = () => {
    if (Boolean(investment) && Number(investment) > Number(currentAsset)) {
      return {
        state: 'error' as const,
        text: '입력한 금액이 보유한 원화보다 커요.',
      };
    }

    if (
      Boolean(investment) &&
      Number(investment) <
        (Number(edittingRobot?.min_principal) || MIN_PRINCIPAL)
    ) {
      return {
        state: 'success' as const,
        text: `이 전략의 최소 투자 금액은 ${edittingRobot?.min_principal.toLocaleString() || '10,000'}원 이에요.`,
      };
    }

    return null;
  };

  const handleAllinClick = () => {
    if (!availableBalance) {
      return;
    }

    setInvestment(availableBalance);
  };

  if (!isSuccess) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.action}>
        <GoBackButton />
      </div>
      <main className={styles.main}>
        <div className={styles.header}>
          <Title text={`실전투자 로봇 작동 설정`} size="large" />
        </div>
        <div className={styles.body}>
          <section className={clsx(styles.section, styles.section1)}>
            <Input
              label="전략"
              placeholder="선택된 전략"
              value={edittingRobot?.name ?? ''}
              onClick={handleStrategyInputClick}
              readOnly
              rightIcon={<CaretRightIcon />}
            />
          </section>
          <section className={clsx(styles.section, styles.section2)}>
            <Input
              label="거래 종목"
              value={edittingRobot?.market.replaceAll('KRW-', '') || ''}
              placeholder="전략에 따라 종목이 선택됩니다."
              readOnly
            />
          </section>
          <section className={clsx(styles.section, styles.section3)}>
            <Input label="거래소" value={'코인원'} readOnly />
            <div className={styles.balance}>
              <dl className={styles.balanceTitle} onClick={handleBalanceClick}>
                <dt>투자 가능 금액</dt>
                <dd className={clsx(isBalanceBodyOpen && styles.open)}>
                  {`${availableBalance.toLocaleString()}원`}
                  <CaretDownIcon />
                </dd>
              </dl>
              <div
                className={clsx(
                  styles.balanceBody,
                  isBalanceBodyOpen && styles.open,
                )}
              >
                <TermWithValue
                  term="코인원 보유 원화"
                  value={`${Math.floor(Number(balance)).toLocaleString()}원`}
                  direction="horizontal"
                  termTextStyle="body2"
                  valueTextStyle="body2"
                  valueTextColor="n50"
                />
                {reservations.map((reservation) => (
                  <TermWithValue
                    key={reservation.trading_id}
                    term={reservation.name}
                    value={`-${Math.floor(Number(reservation.cash_balance)).toLocaleString()}원`}
                    direction="horizontal"
                    termTextStyle="body2"
                    valueTextStyle="body2"
                    valueTextColor="red"
                  />
                ))}
                <Divider type="line" color="n50" />
                <dl className={styles.balanceTitle}>
                  <dt>투자 가능 금액</dt>
                  <dd>{`${availableBalance.toLocaleString()}원`}</dd>
                </dl>
              </div>
            </div>
          </section>
          <section className={clsx(styles.section, styles.section4)}>
            <Input
              label="투자 금액"
              placeholder={`최소 ${edittingRobot?.min_principal.toLocaleString() || '10,000'}원 이상`}
              value={investment === null ? '' : investment.toLocaleString()}
              subText="₩"
              onChange={handleInvestmentChange}
              onBlur={handleInvestmentBlur}
              hasFeedback
              feedback={getInputFeedback()}
            />
            <div />
            <Button theme="primary2" size="small" onClick={handleAllinClick}>
              전액 투자
            </Button>
          </section>
          <AveragePrincipalBanner />
        </div>
        <StartButton
          investment={investment}
          currentAsset={currentAsset}
          setIsAgreementOpen={setIsAgreementOpen}
          setIsRobotStartNoticeOpen={setIsRobotStartNoticeOpen}
          setRobotId={setRobotId}
        />
        <AgreementSheet
          ref={agreementRef}
          isOpen={isAgreementOpen}
          setIsOpen={setIsAgreementOpen}
          onClose={handleBottomSheetClose}
        />
        <StrategiesSheet
          ref={strategiesSheetRef}
          isOpen={isStrategiesOpen}
          setIsOpen={setIsStrategiesOpen}
          onClose={handleBottomSheetClose}
          username={username}
        />
        <RobotStartNoticeSheet
          ref={robotStartNoticeRef}
          isOpen={isRobotStartNoticeOpen}
          onConfirmClick={() => {
            setIsRobotStartNoticeOpen(false);

            if (robotId === null) {
              return;
            }

            safePush(
              routes.tradingrobotDetailRoute({
                username,
                robotId,
              }),
            );
          }}
          onClose={handleBottomSheetClose}
        />
        <BalanceNoticeSheet
          ref={balanceNoticeRef}
          isOpen={isBalanceNoticeOpen}
          onClose={handleBottomSheetClose}
        />
      </main>
    </div>
  );
};

export default MobileAddTradingrobotPage;
