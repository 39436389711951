import { useRef } from 'react';

import { MarketName, CryptoExchange } from '@/constants';
import tradingAPI from '@/features/api/chart/trading';
import * as Sentry from '@sentry/nextjs';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

const ERROR_CODE = {
  DUPLICATE_REQUEST: 'DUPLICATE_REQUEST',
  API_ERROR: 'API_ERROR',
} as const;

interface TradingError {
  code: (typeof ERROR_CODE)[keyof typeof ERROR_CODE];
  message: string;
}

interface TradingVariables {
  tradingId: string;
  robotName: string;
}

interface CreateTradingVariables {
  name: string;
  exchange: CryptoExchange;
  market: MarketName;
  principal: number;
  strategy_id: string;
  robotName: string;
}

type StartTradingResponse = Awaited<ReturnType<typeof tradingAPI.startTrading>>;
type CreateTradingResponse = Awaited<
  ReturnType<typeof tradingAPI.createTrading>
>;

const isTradingError = (error: unknown): error is TradingError =>
  typeof error === 'object' &&
  error !== null &&
  'code' in error &&
  'message' in error;

const useStartTradingMutation = (
  options?: UseMutationOptions<
    StartTradingResponse,
    TradingError,
    TradingVariables
  >,
) => {
  const pendingIdRef = useRef<string | null>(null);

  return useMutation<StartTradingResponse, TradingError, TradingVariables>({
    mutationFn: async ({ tradingId, robotName }) => {
      if (pendingIdRef.current === tradingId) {
        Sentry.captureMessage('Start Trading 중복 요청 감지됨', {
          level: 'warning',
          extra: {
            tradingId,
            robotName: robotName,
          },
        });

        return Promise.reject({
          code: ERROR_CODE.DUPLICATE_REQUEST,
          message: '로봇 작동 요청을 이미 진행중입니다.',
        });
      }

      try {
        Sentry.captureMessage('Start Trading 요청', {
          level: 'info',
          extra: {
            tradingId,
            robotName,
          },
        });

        pendingIdRef.current = tradingId;
        const response = await tradingAPI.startTrading(tradingId);

        Sentry.captureMessage('Start Trading 성공', {
          level: 'info',
          extra: {
            tradingId,
            robotName,
            response,
          },
        });

        return response;
      } catch (error) {
        Sentry.captureMessage('Start Trading 실패', {
          level: 'error',
          extra: {
            error,
            tradingId,
            robotName,
            action: 'startTrading',
          },
        });

        return Promise.reject({
          code: ERROR_CODE.API_ERROR,
          message: '로봇 작동 시작에 실패했습니다.',
        });
      } finally {
        if (pendingIdRef.current === tradingId) {
          pendingIdRef.current = null;
        }
      }
    },
    onError: (error, { robotName, tradingId }) => {
      if (
        isTradingError(error) &&
        error.code === ERROR_CODE.DUPLICATE_REQUEST
      ) {
        Sentry.captureMessage('Start Trading 중복 요청 에러', {
          level: 'warning',
          extra: {
            error,
            robotName,
            tradingId,
          },
        });
        return `[${robotName}] 로봇이 이미 시작 중입니다.`;
      }

      Sentry.captureMessage('Start Trading 실패 - onError', {
        level: 'error',
        extra: {
          error,
          robotName,
          tradingId,
        },
      });
      return `[${robotName}] 로봇 작동 시작에 실패했습니다. 다시 시도해주세요.`;
    },
    ...options,
  });
};

const useCreateTradingMutation = (
  options?: UseMutationOptions<
    CreateTradingResponse,
    TradingError,
    CreateTradingVariables
  >,
) => {
  return useMutation<
    CreateTradingResponse,
    TradingError,
    CreateTradingVariables
  >({
    mutationFn: async ({
      name,
      exchange,
      market,
      principal,
      strategy_id,
      robotName,
    }) => {
      try {
        Sentry.captureMessage('Create Trading 요청', {
          level: 'info',
          extra: {
            name,
            exchange,
            market,
            principal,
            strategy_id,
            robotName,
          },
        });

        const response = await tradingAPI.createTrading({
          name,
          exchange,
          market,
          principal,
          strategy_id,
        });

        Sentry.captureMessage('Create Trading 성공', {
          level: 'info',
          extra: {
            robotName,
            strategy_id,
            response,
          },
        });

        return response;
      } catch (error) {
        Sentry.captureMessage('Create Trading 실패', {
          level: 'error',
          extra: {
            error,
            name,
            exchange,
            market,
            principal,
            strategy_id,
            robotName,
          },
        });

        return Promise.reject({
          code: ERROR_CODE.API_ERROR,
          message: '로봇 생성에 실패했습니다.',
        });
      }
    },
    onError: (error, { robotName }) => {
      Sentry.captureMessage('Create Trading 실패 - onError', {
        level: 'error',
        extra: {
          error,
          robotName,
        },
      });
      return `[${robotName}] 로봇 생성에 실패했습니다. 다시 시도해주세요.`;
    },
    ...options,
  });
};

export default {
  useStartTradingMutation,
  useCreateTradingMutation,
};
