import { PropsWithChildren } from 'react';

import Button from '@/components/Button';
import { ButtonTheme } from '@/components/Button/types';

import Spinner from '@/components/Spinner';
import CloseIcon from '@/images/icons/ic_close_n50_28px.svg';

import styles from '@/components/Modal/ConfirmModal/ConfirmModal.module.scss';

interface ConfirmationModalProps {
  title: string;
  buttonText: string;
  onSubmit: () => void;
  onClose: () => void;
  isClickable?: boolean;
  hasCancelButton?: boolean;
  isLoading?: boolean;
  theme?: ButtonTheme;
}

export const ConfirmModal = ({
  title,
  buttonText,
  children,
  onSubmit,
  onClose,
  isClickable,
  hasCancelButton = true,
  isLoading = false,
  theme = 'primary1',
}: PropsWithChildren<ConfirmationModalProps>) => {
  return (
    <div className={styles.container}>
      <div className={styles.modalTitleWrapper}>
        <span className={styles.modalTitle}>{title}</span>
        <CloseIcon
          viewBox="0 0 32 32"
          className={styles.closeIcon}
          onClick={onClose}
        />
      </div>
      <div className={styles.contentWrapper}>{children}</div>
      <div className={styles.buttonWrapper}>
        {hasCancelButton && (
          <Button onClick={onClose} theme="neutral3" size="medium">
            취소
          </Button>
        )}

        <Button
          onClick={onSubmit}
          isClickable={isClickable}
          theme={theme}
          size="medium"
        >
          {isLoading && <Spinner />}
          <span>{buttonText}</span>
        </Button>
      </div>
    </div>
  );
};
