import HighRiskIcon from '@/images/icons/ic_3level-high.svg';
import LowRiskIcon from '@/images/icons/ic_3level-low.svg';
import MediumRiskIcon from '@/images/icons/ic_3level-medium.svg';

export const getRiskTextByMdd = (mdd: number) => {
  if (mdd < 10) {
    return '안전';
  }

  if (mdd < 30) {
    return '보통';
  }

  return '높음';
};

const riskMap = {
  안전: <LowRiskIcon />,
  보통: <MediumRiskIcon />,
  높음: <HighRiskIcon />,
};

interface RiskIconProps {
  mdd: number;
}

const RiskIcon = ({ mdd }: RiskIconProps) => {
  return riskMap[getRiskTextByMdd(mdd)];
};

export default RiskIcon;
