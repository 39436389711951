import Button from '@/components/Button';
import IconButtonWithTooltip from '@/components/Button/IconButtonWithTooltip';
import Logo from '@/components/Logo';
import SafeLink from '@/components/SafeLink';
import { MenuItem, routes } from '@/constants/';
import { GUIDE_URL, noAuthPages } from '@/constants/routes';
import { amplitudeEventNames, trackEvent } from '@/features/amplitude';
import { useResponsive, useSafeRouter } from '@/hooks';
import ChannelTalkIcon from '@/images/icons/ic_channeltalk_n80_24px.svg';
import GuideIcon from '@/images/icons/ic_guide_n80_24px.svg';
import OpenChatIcon from '@/images/icons/ic_openchat_n80_24px.svg';
import { clsx } from 'clsx';

import { signOut, useSession } from 'next-auth/react';

import styles from './index.module.scss';

const HeaderRightContents = () => {
  const { data: session, status } = useSession();
  const {
    router: { pathname },
    safePush,
  } = useSafeRouter();

  const handleSignOutClick = async () => {
    trackEvent(amplitudeEventNames.BTN_ACCOUNT_SIGNOUT);

    if (noAuthPages.includes(pathname)) {
      await signOut();
      return;
    }

    await signOut({ callbackUrl: routes.signInRoute });
  };

  const handleSignInClick = () => {
    trackEvent(amplitudeEventNames.BTN_ACCOUNT_SIGNIN);
    safePush(routes.signInRoute);
  };

  const handleGuideClick = () => {
    trackEvent(amplitudeEventNames.FORMULA_GUIDE);
  };

  const toggleChannelService = () => {
    trackEvent(amplitudeEventNames.H_MENU_CS);
    window.ChannelIO?.('showMessenger');
    // window.open('https://tradingbank.channel.io/', '_blank');
  };

  const handleCommunityClick = () => {
    trackEvent(amplitudeEventNames.H_MENU_COMMUNITY);
  };

  const handleUserProfileClick = () => {
    trackEvent(amplitudeEventNames.H_MENU_MYPAGE);
  };

  return (
    <div className={styles.headerRightContents}>
      <div className={styles.links}>
        <IconButtonWithTooltip
          tooltipMessage="고객 문의"
          onClick={toggleChannelService}
        >
          <ChannelTalkIcon />
        </IconButtonWithTooltip>
        <IconButtonWithTooltip tooltipMessage="가이드 보기">
          <a
            href={GUIDE_URL}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
            onClick={handleGuideClick}
          >
            <GuideIcon />
          </a>
        </IconButtonWithTooltip>
        <IconButtonWithTooltip tooltipMessage="오픈채팅 입장">
          <a
            href="https://open.kakao.com/o/gOdbpJqf"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
            onClick={handleCommunityClick}
          >
            <OpenChatIcon />
          </a>
        </IconButtonWithTooltip>
      </div>
      <div className={styles.userInfo}>
        {session && (
          <>
            <SafeLink href={routes.myPageRoute(session.username)}>
              <div className={styles.profile} onClick={handleUserProfileClick}>
                <div className={styles.name}>
                  <span>{session.username}</span>
                  <span>님</span>
                </div>
                <div className={styles.email}>{session.user?.email}</div>
              </div>
            </SafeLink>
            <Button onClick={handleSignOutClick} theme="neutral2" size="small">
              로그아웃
            </Button>
          </>
        )}
        {status === 'unauthenticated' && (
          <Button onClick={handleSignInClick} theme="neutral2" size="small">
            로그인
          </Button>
        )}
      </div>
    </div>
  );
};

interface HeaderProps {
  items: MenuItem[];
}

export const Header = ({ items }: HeaderProps) => {
  const { data: session, status } = useSession();
  const { isDesktop } = useResponsive();
  const {
    router: { pathname },
    safePush,
  } = useSafeRouter();

  const handleMenuClick = (amplitudeEventName: string | undefined) => {
    if (!amplitudeEventName) {
      return;
    }

    trackEvent(amplitudeEventName);
  };

  const handleLogoClick = () => {
    trackEvent(amplitudeEventNames.H_TB_LOGO);

    if (status === 'authenticated') {
      safePush(routes.homeRoute);
      return;
    }

    safePush(routes.signInRoute);
  };

  return (
    <header className={styles.root}>
      <div className={styles.left}>
        <Button onClick={handleLogoClick} theme="none" size="none">
          <Logo />
        </Button>
        {session && (
          <ul className={styles.menu}>
            {items.map(
              ({ name, to, pathPattern, amplitudeEventName = undefined }) => {
                if (typeof to !== 'string') {
                  to = to(session.username);
                }

                return (
                  <li
                    key={name}
                    className={clsx(styles.menuItem, {
                      [styles.active]:
                        pathname === to || pathname.includes(pathPattern),
                    })}
                    onClick={() => handleMenuClick(amplitudeEventName)}
                  >
                    <SafeLink href={to}>{name}</SafeLink>
                  </li>
                );
              },
            )}
          </ul>
        )}
      </div>
      {isDesktop && (
        <div className={styles.right}>
          <HeaderRightContents />
        </div>
      )}
    </header>
  );
};
