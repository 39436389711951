import React, { Component, ComponentType, ErrorInfo, ReactNode } from 'react';

import MainLayout from '@/components/Layouts/MainLayout';
import SafeLink from '@/components/SafeLink';
import { routes } from '@/constants/';
import { useResponsive } from '@/hooks';
import ErrorMobileIllust from '@/images/illust/il_error_280px.svg';
import ErrorIllust from '@/images/illust/il_error_640px.svg';
import * as Sentry from '@sentry/nextjs';

import styles from './index.module.scss';

interface ResponsiveProps {
  isMobile: boolean;
}

const withResponsive = <P extends object>(
  WrappedComponent: ComponentType<P & ResponsiveProps>,
): ComponentType<P> => {
  return (props: P) => {
    const { isMobile } = useResponsive();
    return <WrappedComponent {...props} isMobile={isMobile} />;
  };
};

interface Props extends ResponsiveProps {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render shows the fallback UI.
    Sentry.captureException(error);
    console.log('error', error);

    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    Sentry.captureException(error);
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const { isMobile } = this.props;

      if (isMobile) {
        return (
          <>
            <div className={styles.root}>
              <p className={styles.message1}>
                서버로부터 데이터를 불러올 수 없습니다. <br />
                잠시 후 이용해주세요.
              </p>
              <div>
                <ErrorMobileIllust />
                <p className={styles.message2}>
                  항상 안정화를 위해 노력하겠습니다.
                </p>
              </div>
              <SafeLink href={routes.homeRoute}>
                <div className={styles.homeButton}>홈으로</div>
              </SafeLink>
            </div>
          </>
        );
      }

      return (
        <MainLayout>
          <div className={styles.root}>
            <p className={styles.message1}>
              서버로부터 데이터를 불러올 수 없습니다. <br />
              잠시 후 이용해주세요.
            </p>
            <div>
              <ErrorIllust />
              <p className={styles.message2}>
                항상 안정화를 위해 노력하겠습니다.
              </p>
            </div>
          </div>
        </MainLayout>
      );
    }

    return this.props.children;
  }
}

export default withResponsive(ErrorBoundary);
