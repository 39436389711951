import { env } from '@/features/env';
import axios from 'axios';

import { getSession } from 'next-auth/react';

const BASE_URL = `${env.ACCOUNT_SERVICE_HOSTNAME}/api/v1/account`;

export const accountAPI = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

accountAPI.interceptors.request.use(async (config) => {
  const session = await getSession();

  if (session) {
    if (!config.headers) {
      config.headers = {};
    }

    const { accessToken, tokenType } = session;
    config.headers.Authorization = `${tokenType} ${accessToken}`;
  }

  return config;
});

accountAPI.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    console.debug(error);
    return Promise.reject(error);
  },
);

// MEMO: https://timepercentworkspace.slack.com/archives/C04GPV5H6R1/p1741315024853929
export const appleAPI = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'text/plain' },
  withCredentials: true,
});

appleAPI.interceptors.request.use(async (config) => {
  const session = await getSession();

  if (session) {
    if (!config.headers) {
      config.headers = {};
    }

    const { accessToken, tokenType } = session;
    config.headers.Authorization = `${tokenType} ${accessToken}`;
  }

  return config;
});

appleAPI.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    console.debug(error);
    return Promise.reject(error);
  },
);
