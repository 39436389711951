/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-key */
import { useState, useEffect, PropsWithChildren } from 'react';

import type { AppProps } from 'next/app';
import Head from 'next/head';

import ErrorBoundary from '@/components/ErrorBoundary';
import MobileAppLayout from '@/components/Layouts/MobileAppLayout';
import UnSupportedLayout from '@/components/Layouts/UnSupportedLayout';
import MultiProvider from '@/components/MultiProvider';
import SnackbarContextProvider from '@/components/Snackbar';
import { localStorageKeys, routes } from '@/constants/';
import { amplitudeEventNames, initAmplitude } from '@/features/amplitude';
import {
  useNacho,
  useResponsive,
  useSafeRouter,
  useSigninDetection,
  useSyncUsername,
} from '@/hooks';
import { queryClient } from '@/hooks/useQuery/base';
import { logEvent, setUserId } from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/nextjs';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Session } from 'next-auth';
import { NuqsAdapter } from 'nuqs/adapters/next/pages';
// import { scan } from 'react-scan';

import { useLocalStorage } from 'react-use';

import { SessionProvider, useSession } from 'next-auth/react';

import '@/styles/index.scss';

const pageTitlesByPath: { [key: string]: string } = {
  '/': '트레이딩뱅크 | 퀀트 트레이딩 플랫폼',
  '/backtesting': '전략 생성 | 트레이딩뱅크',
  '/marketplace': '전략 마켓 | 트레이딩뱅크',
  '/blog': '블로그 | 트레이딩뱅크',
  '/backtesting/loading': '백테스팅 진행중 | 트레이딩뱅크',
  '/backtesting/result': '백테스팅 결과 | 트레이딩뱅크',
  '/user/[username]/strategy': '내 전략 | 트레이딩뱅크',
  '/user/[username]/tradingrobot': '내 로봇 | 트레이딩뱅크',
  '/user/[username]/asset': '내 자산 | 트레이딩뱅크',
  '/signin': '로그인 | 트레이딩뱅크',
  '/signup': '회원가입 | 트레이딩뱅크',
  '/policy/terms': '이용약관 | 트레이딩뱅크',
  '/policy/privacy': '개인정보정책 | 트레이딩뱅크',
  '/onboarding/firstvisit': '온보딩 | 트레이딩뱅크',
};

const exceptionPaths = ['/callback'];
const mobileSupportedPaths = [
  routes.rootRoute,
  routes.signInRoute,
  routes.signUpRoute,
  routes.homeRoute,
  routes.termsRoute,
  routes.privacyRoute,
  routes.blogRoute,
  '/user/[username]',
];
const responsiveSupportedPaths = [...exceptionPaths, ...mobileSupportedPaths];

const AuthPageRedirectLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { isApp, getCustomUserId, setCustomUserId } = useNacho();
  const { router, safePush } = useSafeRouter();
  const { status, data: session } = useSession();

  useSigninDetection();
  useEffect(() => {
    if (!session) {
      return;
    }

    Sentry.setUser({
      username: session.username,
      email: session.user.email || 'email null or undefined',
    });

    if (session.user.email) {
      setUserId(session.user.email);
    }

    if (!isApp) {
      return;
    }

    getCustomUserId?.((status, customUserId) => {
      if (status === 'error') {
        Sentry.captureMessage('Getting custom user id failed!', {
          level: 'info',
        });
        return;
      }

      if (customUserId) {
        return;
      }

      setCustomUserId?.(session.user.email || 'email null or undefined');
    });
  }, [session, isApp, getCustomUserId, setCustomUserId]);

  useEffect(() => {
    if (session?.errorStatus) {
      delete session.errorStatus;
    }
  }, [session]);

  useSyncUsername();

  if (routes.noAuthPages.includes(router.pathname)) {
    return <>{children}</>;
  }

  if (status === 'unauthenticated') {
    safePush(routes.signInRoute);
    return null;
  }

  if (status === 'authenticated') {
    return <>{children}</>;
  }

  return null;
};

const ResponsiveLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { router } = useSafeRouter();
  const { pathname } = router;

  return (
    <>
      {responsiveSupportedPaths.some((path) => pathname.includes(path)) ? (
        children
      ) : (
        <UnSupportedLayout>{children}</UnSupportedLayout>
      )}
    </>
  );
};

const App = ({
  Component,
  pageProps,
}: AppProps<{
  session: Session;
}>) => {
  const [isHydrated, setIsHydrated] = useState(false);
  const { router } = useSafeRouter();
  const { pathname } = router;
  const { isMobile } = useResponsive();
  const [hasVisited, setHasVisited] = useLocalStorage(
    localStorageKeys.AMPLITUDE_FIRST_SESSION,
  );

  const { isApp, appVersion } = useNacho();

  // useEffect(() => {
  //   scan({
  //     enabled: false,
  //     // log: true, // logs render info to console (default: false)
  //   });
  // }, []);

  useEffect(() => {
    // console.warn 재정의
    const originalWarn = console.warn;
    console.warn = (...args) => {
      if (
        args[0] &&
        typeof args[0] === 'string' &&
        args[0].includes('[NACHOCODE]')
      ) {
        // nachocode, `[NACHOCODE]`와 관련된 경고는 무시
        return;
      }
      originalWarn.apply(console, args);
    };
  }, []);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  useEffect(() => {
    initAmplitude();
    if (hasVisited) {
      return;
    }

    logEvent(amplitudeEventNames.AMPLITUDE_FIRST_SESSION_STARTED, {
      timestamp: new Date(),
      referrer: document.referrer,
      url: window.location.href,
      pageTitle: document.title,
      userAgent: navigator.userAgent,
      isApp: isApp ?? 'undefined',
      appVersion: appVersion ?? 'undefined',
    });

    setHasVisited(true);
  }, [hasVisited, setHasVisited, isApp, appVersion]);

  if (!isHydrated) {
    return (
      <Head>
        <title>{pageTitlesByPath[pathname] || '트레이딩뱅크'}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
        />
      </Head>
    );
  }

  return (
    <>
      <Head>
        <title>{pageTitlesByPath[pathname] || '트레이딩뱅크'}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1, viewport-fit=cover"
        />
      </Head>
      <ErrorBoundary isMobile={isMobile}>
        <MultiProvider
          providers={[
            <SessionProvider session={pageProps.session} children={null} />,
            <QueryClientProvider client={queryClient} />,
            <AuthPageRedirectLayout />,
            <SnackbarContextProvider />,
            <ResponsiveLayout />,
            <MobileAppLayout />,
          ]}
        >
          <NuqsAdapter>
            <Component {...pageProps} />
          </NuqsAdapter>
          <ReactQueryDevtools initialIsOpen={false} />
        </MultiProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
