import { useState } from 'react';

import Image from 'next/image';

import Button from '@/components/Button';

import Tag from '@/components/Content/Tag';
import ScoreRangeIcon from '@/components/Icon/ScoreRangeIcon';
import MainLayout from '@/components/Layouts/MainLayout';
import ResponsivePageLayout from '@/components/Layouts/ResponsivePageLayout';

import MobileMarketplacePage from '@/components/Mobile/Marketplace';
import Pagination from '@/components/Pagination';
import SafeLink from '@/components/SafeLink';
import Spinner from '@/components/Spinner';

import { routes } from '@/constants/';
import { amplitudeEventNames, trackEvent } from '@/features/amplitude';
import { PublicStrategyOverview } from '@/features/api/chart/strategy/type';

import { decimalFormatter } from '@/features/utils';
import { useSafeRouter } from '@/hooks';
import strategyQuery from '@/hooks/useQuery/strategy';
import clsx from 'clsx';

import { useSession } from 'next-auth/react';

import styles from './index.module.scss';

const STRATEGY_COUNT_PER_PAGE = 10;
const RATEL_IDS = [
  '라텔 테스트1',
  '라텔 네이버',
  'user-9R86LfqkkfDo',
  'user-aR2d3retZGFH',
];
export const CERTIFIED_USERS = [
  '제이크',
  'user-NTztI7LducDo', // 제이크
  '제닛',
  '퀀트마스터',
  '하워드',
  'user-fcJdKkoXxgpN', // 메이
  'user-xRBN5RFvslDh', // 987@test.com
  'user-ahwFFi8UW03F', // 크리스
  // 코코
  'user-sKcBfwxvgCj3',
  'user-ubyijH8MBB8l',
  'user-l1REXJWHTvDE',
  'user-7yGWiVomfa9N',
  'user-xx6K8Pg6znlW',
  ...RATEL_IDS,
];

interface MarketplaceStrategyListRowProps {
  strategy: PublicStrategyOverview;
}

const MarketplaceStrategyListRow = ({
  strategy,
}: MarketplaceStrategyListRowProps) => {
  const { safePush } = useSafeRouter();
  const { data: session } = useSession();

  const {
    is_following: isFollowing,
    author,
    name,
    cagr,
    score,
    strategy_id: strategyId,
  } = strategy;

  const isMine = session?.username === author;

  const handleRowClick = () => {
    trackEvent(amplitudeEventNames.STRG_DETAIL, {
      score,
      cagr,
      value: name,
    });
  };

  const handleFollowClick = () => {
    trackEvent(amplitudeEventNames.BTN_MARKETPLACE_SELECT, {
      value: name,
      cagr,
      score,
    });
    safePush(routes.marketplaceStrategyRoute(strategyId));
  };

  return (
    <li className={styles.row} onClick={handleRowClick}>
      <SafeLink href={routes.marketplaceStrategyRoute(strategyId)}>
        <div className={styles.link}>
          <div className={clsx(styles.column, styles.column1)}>
            <div className={styles.title}>
              <div>{name}</div>
            </div>
            <div className={styles.author}>@{author}</div>
          </div>
          <div
            className={clsx(
              styles.column,
              styles.column2,
              cagr > 0 && 'positive',
              cagr < 0 && 'negative',
            )}
          >
            {Boolean(cagr) ? `${decimalFormatter(cagr)}%` : '-'}
          </div>
          <div className={styles.column}>
            <Tag
              text={`${score}점`}
              size="large"
              theme="neutral2"
              postIcon={<ScoreRangeIcon score={score} size="small" />}
            />
          </div>
          <div className={styles.column}>
            {!isMine && (
              <Button
                theme={isFollowing ? 'primary2' : 'primary1'}
                size="medium"
                onClick={handleFollowClick}
              >
                {isFollowing ? '팔로우 취소' : '팔로우하기'}
              </Button>
            )}
          </div>
        </div>
      </SafeLink>
    </li>
  );
};

const DesktopMarketplacePage = () => {
  const { safePush } = useSafeRouter();
  const { data: session } = useSession();

  const { status, data: allPublicStrategies = [] } =
    strategyQuery.useAllPublicStrategies();

  // TODO: 페이지네이션 query parmams로 변경
  const [currentPage, setCurrentPage] = useState(1);

  const handleRegisterClick = () => {
    trackEvent(amplitudeEventNames.BTN_MARKETPLACE_ADD_START);
    safePush(routes.marketplaceRegisterStrategyRoute);
  };

  return (
    <MainLayout>
      <div className={styles.root}>
        <div className={styles.banner}>
          <Image
            src="/images/marketplace-banner.png"
            alt="마켓 배너"
            layout="fill"
            objectFit="contain"
            unoptimized
          />
          <div className={styles.textContainer}>
            <h1 className={styles.pageTitle}>마켓</h1>
            <div className={styles.description}>
              전문 퀀트 트레이더의 투자 전략을 살펴보고,
              <br />
              마음에 드는 전략을 트레이딩 로봇에 연결해보세요
            </div>
          </div>
        </div>

        <div className={styles.contents}>
          {session && CERTIFIED_USERS.includes(session.username) && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '16px',
              }}
            >
              <Button
                theme="primary1"
                size="large"
                onClick={handleRegisterClick}
              >
                너한테만 보이는 전략 등록하기 버튼
              </Button>
            </div>
          )}
          {status === 'loading' && (
            <div className={styles.loading}>
              <Spinner />
            </div>
          )}
          {status === 'success' && (
            <div className={styles.strategyList}>
              <div className={styles.headerRow}>
                <div>전략 이름 / 크리에이터</div>
                <div>연간 수익률</div>
                <div>점수</div>
                <div>팔로우</div>
              </div>
              <ol>
                {allPublicStrategies
                  .slice(
                    (currentPage - 1) * STRATEGY_COUNT_PER_PAGE,
                    currentPage * STRATEGY_COUNT_PER_PAGE,
                  )
                  .map((strategy) => (
                    <MarketplaceStrategyListRow
                      key={strategy.id}
                      strategy={strategy}
                    />
                  ))}
              </ol>
              <Pagination
                totalPageCount={Math.ceil(
                  allPublicStrategies.length / STRATEGY_COUNT_PER_PAGE,
                )}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

const MarketplacePage = () => {
  return (
    <ResponsivePageLayout
      desktop={<DesktopMarketplacePage />}
      mobile={<MobileMarketplacePage />}
    />
  );
};

export default MarketplacePage;
