import Score1IconSmall from '@/images/icons/ic_level6-1_20px.svg';
import Score1IconLarge from '@/images/icons/ic_level6-1_36px.svg';
import Score2IconSmall from '@/images/icons/ic_level6-2_20px.svg';
import Score2IconLarge from '@/images/icons/ic_level6-2_36px.svg';
import Score3IconSmall from '@/images/icons/ic_level6-3_20px.svg';
import Score3IconLarge from '@/images/icons/ic_level6-3_36px.svg';
import Score4IconSmall from '@/images/icons/ic_level6-4_20px.svg';
import Score4IconLarge from '@/images/icons/ic_level6-4_36px.svg';
import Score5IconSmall from '@/images/icons/ic_level6-5_20px.svg';
import Score5IconLarge from '@/images/icons/ic_level6-5_36px.svg';
import Score6IconSmall from '@/images/icons/ic_level6-6_20px.svg';
import Score6IconLarge from '@/images/icons/ic_level6-6_36px.svg';
import clsx from 'clsx';

import styles from './ScoreRangeIcon.module.scss';

export const getTextByScore = (score: number) => {
  if (score <= 40) {
    return '고위험 노출';
  }

  if (score >= 41 && score <= 50) {
    return '리스크 관리 필요';
  }

  if (score >= 51 && score <= 60) {
    return '안정성 미흡';
  }

  if (score >= 61 && score <= 70) {
    return '보통 수준 수익성';
  }

  if (score >= 71 && score <= 80) {
    return '양호한 수익 잠재력';
  }

  if (score >= 81) {
    return '안정적 고수익 가능';
  }

  return '안정적 고수익 가능';
};

const scoreMap = {
  '고위험 노출': {
    large: <Score6IconLarge />,
    small: <Score6IconSmall />,
  },
  '리스크 관리 필요': {
    large: <Score5IconLarge />,
    small: <Score5IconSmall />,
  },
  '안정성 미흡': {
    large: <Score4IconLarge />,
    small: <Score4IconSmall />,
  },
  '보통 수준 수익성': {
    large: <Score3IconLarge />,
    small: <Score3IconSmall />,
  },
  '양호한 수익 잠재력': {
    large: <Score2IconLarge />,
    small: <Score2IconSmall />,
  },
  '안정적 고수익 가능': {
    large: <Score1IconLarge />,
    small: <Score1IconSmall />,
  },
};

interface ScoreRangeIconProps {
  score: number;
  size: 'large' | 'small';
}

const ScoreRangeIcon = ({ score, size }: ScoreRangeIconProps) => {
  return (
    <div className={clsx(styles.root, styles[size])}>
      {scoreMap[getTextByScore(score)][size]}
    </div>
  );
};

export default ScoreRangeIcon;
