import { accountAPI } from '@/features/api/account/base';

import { SignUpRequest, UserInfo, UserTerms } from './type';

const getUserInfo = async (): Promise<UserInfo> => {
  const response = await accountAPI.get('/user/');
  return response.data;
};

const createUser = async (data: SignUpRequest) => {
  const response = await accountAPI.post('/user', {
    email: data.email,
    password: data.password,
    password2: data.password2,
  });

  return response;
};

const deleteUser = async () => {
  return await accountAPI.delete('/user');
};

const getTerms = async (): Promise<UserTerms> => {
  const response = await accountAPI.get('/user/terms');
  return response.data;
};

const agreeRoboTerms = async () => {
  const response = await accountAPI.put('/user/terms', {
    robo_advisor: true,
  });

  return response.status;
};

const registerDeviceToken = async ({
  deviceToken,
}: {
  deviceToken: string;
}) => {
  const response = await accountAPI.post('/user/device', {
    device_token: deviceToken,
  });

  return response;
};

export default {
  getUserInfo,
  createUser,
  deleteUser,
  getTerms,
  agreeRoboTerms,
  registerDeviceToken,
};
