import { useEffect, useState } from 'react';

const initializeNachocode = async () => {
  if (typeof window === 'undefined' || typeof Nachocode === 'undefined') {
    return;
  }

  await Nachocode.initAsync('CtTKrAiTaTdnCavJxIJl', {
    logger: true,
  });
};

interface NachoState {
  isApp?: boolean;
  isWeb?: boolean;
  appVersion?: string;
  setPullToRefresh?: typeof Nachocode.setting.setPullToRefresh;
  getCustomUserId?: typeof Nachocode.preference.getCustomUserId;
  setCustomUserId?: typeof Nachocode.preference.setCustomUserId;
  pushToken?: string;
  registerPushToken?: typeof Nachocode.push.registerPushToken;
  checkPermission?: typeof Nachocode.permission.checkPermission;
  sendLocalPush?: typeof Nachocode.push.sendLocalPush;
}

const DEFAULT_RETURN_VALUE: NachoState = {
  isApp: undefined,
  isWeb: undefined,
  appVersion: undefined,
  setPullToRefresh: undefined,
  getCustomUserId: undefined,
  setCustomUserId: undefined,
  pushToken: undefined,
  registerPushToken: undefined,
  checkPermission: undefined,
  sendLocalPush: undefined,
};

export const useNacho = () => {
  const [nachoState, setNachoState] =
    useState<NachoState>(DEFAULT_RETURN_VALUE);

  useEffect(() => {
    const initialize = async () => {
      await initializeNachocode();

      if (typeof Nachocode === 'undefined') {
        return;
      }

      setNachoState({
        isApp: Nachocode.env.isApp(),
        isWeb: Nachocode.env.isWeb(),
        appVersion: Nachocode.app.getCurrentAppVersion(),
        setPullToRefresh: Nachocode.setting.setPullToRefresh,
        getCustomUserId: Nachocode.preference.getCustomUserId,
        setCustomUserId: Nachocode.preference.setCustomUserId,
        pushToken: await Nachocode.push.getPushToken(),
        registerPushToken: Nachocode.push.registerPushToken,
        checkPermission: Nachocode.permission.checkPermission,
        sendLocalPush: Nachocode.push.sendLocalPush,
      });
    };

    initialize();
  }, []);

  return nachoState;
};
