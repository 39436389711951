import SafeLink from '@/components/SafeLink';
import { routes } from '@/constants/';
import { amplitudeEventNames, trackEvent } from '@/features/amplitude';
import { useSafeRouter } from '@/hooks';
// import NavAssetIcon from '@/images/icons/ic_nav-asset_24px.svg';
import NavBlogIcon from '@/images/icons/ic_nav-blog_24px.svg';
import NavRobotIcon from '@/images/icons/ic_nav-robot_24px.svg';
import NavSettingsIcon from '@/images/icons/ic_nav-setting_24px.svg';
import NavStrategyIcon from '@/images/icons/ic_nav-strategy_24px.svg';
import clsx from 'clsx';

import { useSession } from 'next-auth/react';

import styles from '@/components/Navigation/MobileNavigation.module.scss';

const MobileNavigation = () => {
  const { router } = useSafeRouter();
  const { data: session } = useSession();

  // MEMO: 튜토리얼 중에 네비게이션에 눌리지 않아야 함.
  // 이를 위해서 부득이하게 비활성화 옵션을 추가하게 됨 (TB-6862_QA_4), 좋은 방법이라고 생각하지는 않음
  const isClickable = !router.pathname.includes('onboarding/tutorial');

  if (!session) {
    return null;
  }

  const menuItems = [
    {
      name: '로봇',
      icon: <NavRobotIcon />,
      link: routes.tradingrobotRoute(session.username),
      pathname: '/user/[username]/tradingrobot',
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_ROBOT);
      },
    },
    {
      name: '전략',
      icon: <NavStrategyIcon />,
      link: routes.marketplaceRoute,
      pathnames: ['/user/[username]/strategy', routes.marketplaceRoute],
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_STRTG);
      },
    },
    // {
    //   name: '자산',
    //   icon: <NavAssetIcon />,
    //   link: routes.myAssetPageRoute(session.username),
    //   pathname: '/user/[username]/asset',
    //   onClick: () => {
    //     trackEvent(amplitudeEventNames.H_MENU_ASSET);
    //   },
    // },
    {
      name: '블로그',
      icon: <NavBlogIcon />,
      link: routes.blogRoute,
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_BT_BLOG);
      },
    },
    {
      name: '더보기',
      icon: <NavSettingsIcon />,
      link: routes.myPageRoute(session.username),
      pathname: '/user/[username]',
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_MYPAGE);
      },
    },
  ];

  return (
    <nav className={styles.root}>
      {menuItems.map(({ name, icon, link, pathname, pathnames, onClick }) => {
        return (
          <div
            className={clsx(
              styles.navItem,
              (link === router.pathname ||
                pathname === router.pathname ||
                pathnames?.includes(router.pathname)) &&
                styles.activeTab,
            )}
            key={name}
            onClick={isClickable ? onClick : undefined}
          >
            {isClickable ? (
              <SafeLink href={link}>
                {icon}
                <span>{name}</span>
              </SafeLink>
            ) : (
              <div className={styles.fakeLink}>
                {icon}
                <span>{name}</span>
              </div>
            )}
          </div>
        );
      })}
    </nav>
  );
};

export default MobileNavigation;
