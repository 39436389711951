import Tag from '@/components/Content/Tag';

import TermWithValue from '@/components/Content/TermWithValue';
import Title from '@/components/Content/Title';

import ScoreRangeIcon from '@/components/Icon/ScoreRangeIcon';
import { MyStrategyOverview } from '@/features/api/chart/strategy/type';
import { calculateTimeDifferece, signedFormatter } from '@/features/utils';
import { useSafeRouter } from '@/hooks';
import { MIN_PRINCIPAL } from '@/pages/user/[username]/tradingrobot/new';
import { format } from 'date-fns';

import styles from './index.module.scss';

interface StrategyCardProp {
  strategy: MyStrategyOverview;
}

const StrategyCard = ({ strategy }: StrategyCardProp) => {
  const { router } = useSafeRouter();
  const { query } = router;
  const { username } = query;
  const {
    name,
    created_at: createdAt,
    start = '',
    end = '',
    markets = [],
    score,
    ror,
    author,
    min_principal: minPrincipal,
  } = strategy;

  const isMine = author === username;
  const market = markets[0].replace('KRW-', '');
  const period = `${format(new Date(start), 'yyyy.MM.dd')} ~ ${format(
    new Date(end),
    'yyyy.MM.dd',
  )}`;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.tagWithDate}>
          <div className={styles.tags}>
            {!isMine && <Tag text="팔로잉" size="small" theme="primary2" />}
            {market && <Tag text={market} size="small" theme="neutral2" />}
            {minPrincipal && minPrincipal > MIN_PRINCIPAL && (
              <Tag text={'최소 투자 금액'} size="small" theme="yellow2" />
            )}
          </div>
          <time className={styles.createdAt}>
            {calculateTimeDifferece({
              start: new Date(createdAt),
              postfix: '전',
            })}
          </time>
        </div>
        <Title text={name} size="small" />
        <time className={styles.period}>{period}</time>
      </div>
      <div className={styles.body}>
        <TermWithValue
          term="누적 수익률"
          value={ror}
          displayValue={`${signedFormatter(ror)}%`}
          hasSign
          termTextStyle="caption"
          valueTextStyle="title2"
        />
        <TermWithValue
          term="점수"
          value={`${score}점`}
          termTextStyle="caption"
          valueTextStyle="title2"
          tag={
            <Tag
              text=""
              size="small"
              theme="neutral1"
              preIcon={<ScoreRangeIcon score={score} size="large" />}
            />
          }
        />
      </div>
    </div>
  );
};

export default StrategyCard;
