import { useCallback, useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import * as Sentry from '@sentry/nextjs';

import { UrlObject } from 'url';

export const useSafeRouter = () => {
  const onChangingRef = useRef(false);
  const router = useRouter();

  const handleRouteChange = useCallback(() => {
    onChangingRef.current = false;
  }, []);

  const handleRouteError = useCallback(
    (error: Error, path: string) => {
      Sentry.captureException(error, {
        tags: {
          routePath: path,
          component: 'useSafeRouter',
        },
        extra: {
          error,
          currentUrl: router.asPath,
          targetUrl: path,
        },
      });
      onChangingRef.current = false;
    },
    [router],
  );

  const safePush = useCallback(
    (path: string | UrlObject) => {
      if (onChangingRef.current) {
        return;
      }
      onChangingRef.current = true;
      router.push(path);
    },
    [router],
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeError', handleRouteError);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeError', handleRouteError);
    };
  }, [router, handleRouteChange, handleRouteError]);
  return { router, safePush };
};
