import TabItem from '@/components/Header/TabItem';

import styles from './index.module.scss';

interface TabInfo {
  route: string;
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

interface TabProp {
  activeRoute: string;
  tabs: TabInfo[];
  type: 'tab1' | 'tab2'; // 아직은 둘 다 모바일용 (각각 depth=1, 2에 대한 탭)
}

const Tab = ({ activeRoute, tabs, type }: TabProp) => {
  if (type === 'tab1') {
    return (
      <nav className={styles.tab1}>
        {tabs.map(({ onClick, route, title }) => {
          const isActive = activeRoute.includes(route);

          return (
            <TabItem
              key={route}
              isActive={isActive}
              size={'small'}
              hasBorder={isActive}
              route={route}
              title={title}
              onClick={onClick}
            />
          );
        })}
      </nav>
    );
  }

  if (type === 'tab2') {
    return (
      <nav className={styles.tab2}>
        {tabs.map(({ onClick, route, title }) => {
          const isActive = route.includes(activeRoute);

          return (
            <TabItem
              key={route}
              isActive={isActive}
              size={'small'}
              hasBorder
              route={route}
              title={title}
              onClick={onClick}
            />
          );
        })}
      </nav>
    );
  }

  return <></>;
};

export default Tab;
