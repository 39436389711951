import { create } from 'zustand';

import { initialTradingrobotState } from './state';

import { TradingrobotStore } from './type';

const useTradingobotStore = create<TradingrobotStore>((set) => ({
  ...initialTradingrobotState,
  actions: {
    setEdittingRobot: (robot) => set(() => ({ edittingRobot: robot })),
    setStartingRobot: (robot) => set(() => ({ startingRobot: robot })),
    setStoppingRobot: (robot) => set(() => ({ stoppingRobot: robot })),
    resetRobots: () => set(() => initialTradingrobotState),
  },
}));

export default useTradingobotStore;
