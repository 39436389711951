import clsx from 'clsx';

import styles from './index.module.scss';

interface TabItemProp {
  isActive: boolean;
  size: 'small' | 'large';
  hasBorder: boolean;
  route: string;
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const TabItem = ({
  isActive,
  size,
  hasBorder,
  route,
  title,
  onClick,
}: TabItemProp) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(
        styles.root,
        styles[size],
        isActive && styles.active,
        hasBorder && styles.border,
      )}
      key={route}
    >
      {title}
    </button>
  );
};

export default TabItem;
