import React, {
  createContext,
  useId,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';

import ReactDOM from 'react-dom';

import GreenCheckIcon from '@/images/icons/ic_check-circle_green60n10_24px.svg';
import ExclamationIcon from '@/images/icons/ic_exclamation-circle_red60p10_24px.svg';

import styles from './index.module.scss';

type Mode = 'success' | 'error';

interface SnackbarContents {
  mode: Mode;
  text: string;
}

interface SnackbarProps {
  contents: SnackbarContents;
}

const Snackbar = ({ contents }: SnackbarProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        {contents.mode === 'success' && <GreenCheckIcon />}
        {contents.mode === 'error' && <ExclamationIcon />}
      </div>
      <span className={styles.message}>{contents.text}</span>
    </div>
  );
};

const SnackbarPortal = ({ contents }: SnackbarProps) => {
  return ReactDOM.createPortal(<Snackbar contents={contents} />, document.body);
};

interface SnackbarContextProps {
  setSnackbarContents: (message: SnackbarContents) => void;
}

export const SnackbarContext = createContext<SnackbarContextProps>({
  setSnackbarContents: () => {},
});

const SnackbarContextProvider = ({ children }: React.PropsWithChildren) => {
  const [contents, setContents] = useState<SnackbarContents | null>(null);
  const id = useId();
  const timeoutRef = useRef<number | null>(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const showSnackbar = useCallback((contents: SnackbarContents) => {
    resetTimeout();
    setContents(contents);
    timeoutRef.current = window.setTimeout(() => {
      setContents(null);
      resetTimeout();
    }, 1500);
  }, []);

  const contextValue = useMemo(
    () => ({ setSnackbarContents: showSnackbar }),
    [showSnackbar],
  );

  return (
    <>
      {contents && <SnackbarPortal key={id} contents={contents} />}
      <SnackbarContext.Provider value={contextValue}>
        {children}
      </SnackbarContext.Provider>
    </>
  );
};

export default SnackbarContextProvider;
