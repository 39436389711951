import { CryptoExchange, queryKeys } from '@/constants/';

import tradingApi from '@/features/api/chart/trading';
import {
  TradingHolding,
  TradingLog,
  TradingrobotDetail,
  TradingrobotState,
  TradingHistory,
  ReservedPrincipal,
} from '@/features/api/chart/trading/type';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useSession } from 'next-auth/react';

const useMyCurrentAssetToExchange = ({
  username,
  exchange = 'coinone',
  options,
}: {
  username: string;
  exchange?: CryptoExchange;
  options?: UseQueryOptions<number | null>;
}) =>
  useQuery<number | null>(
    [queryKeys.MY_CURRENT_ASSET, { username }],
    async () => await tradingApi.getCurrentAssetToExchange(exchange),
    { ...options },
  );

/**
 * 유저가 갖고 있는 모든 작동,휴면 상태의 로보어드바이저를 조회
 */
const useAllTrading = () => {
  const { data: session } = useSession();

  return useQuery(
    [queryKeys.ALL_TRADING, { username: session?.username }],
    async () => await tradingApi.getAllTrading(),
  );
};

interface TradingQuery<T> {
  tradingId: string;
  options?: UseQueryOptions<T>;
}

const useTradingById = ({
  tradingId,
  options,
}: TradingQuery<TradingrobotDetail>) => {
  return useQuery<TradingrobotDetail>(
    [queryKeys.TRADING, { tradingId }],
    async () => await tradingApi.getTrading(tradingId),
    { ...options },
  );
};

const useTradingStateById = ({
  tradingId,
  options,
}: TradingQuery<TradingrobotState>) => {
  return useQuery<TradingrobotState>(
    [queryKeys.TRADING, { tradingId }],
    async () => await tradingApi.getTradingState(tradingId),
    { ...options },
  );
};

const useTradinglog = ({ tradingId, options }: TradingQuery<TradingLog[]>) => {
  return useQuery<TradingLog[]>(
    [queryKeys.TRADING_LOG, { tradingId }],
    async () => await tradingApi.getTradinglog(tradingId),
    { ...options },
  );
};

const useTradingHoldings = ({
  tradingId,
  options,
}: TradingQuery<TradingHolding[]>) => {
  return useQuery<TradingHolding[]>(
    [queryKeys.TRADING_HOLDINGS, { tradingId }],
    async () => await tradingApi.getTradingHoldings(tradingId),
    { ...options },
  );
};

const useTradingHistory = ({
  tradingId,
  options,
}: TradingQuery<TradingHistory>) => {
  return useQuery<TradingHistory>(
    [queryKeys.TRADING_HISTORY, { tradingId }],
    async () => await tradingApi.getTradingHistory(tradingId),
    { ...options },
  );
};

const useReservedPrincipal = ({
  exchange,
  options,
}: {
  exchange: CryptoExchange;
  options?: UseQueryOptions<ReservedPrincipal>;
}) => {
  return useQuery<ReservedPrincipal>(
    [queryKeys.RESERVED_PRINCIPAL, { exchange }],
    async () => await tradingApi.getReservedPrincipal(exchange),
    { ...options },
  );
};
export default {
  useMyCurrentAssetToExchange,
  useAllTrading,
  useTradingById,
  useTradingStateById,
  useTradinglog,
  useTradingHoldings,
  useTradingHistory,
  useReservedPrincipal,
};
