import { useState, useEffect } from 'react';

import ScrollTopIcon from '@/images/icons/ic_scrolltop.svg';

import styles from './index.module.scss';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityToggle = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleVisibilityToggle);
    return () => window.removeEventListener('scroll', handleVisibilityToggle);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.scrollToTop}>
      <button onClick={handleClick} className={styles.scrollButton}>
        <ScrollTopIcon />
      </button>
    </div>
  );
};

export default ScrollToTopButton;
